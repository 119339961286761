.component{
    &-title{
        margin-bottom: 70px;
        text-align: center;
        @include BP_tbsp{
            @include VW(margin-bottom,70,$tb);
        }

        @include w_sp{
            @include VW(margin-bottom,65);
        }
    }
    &-layout{
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        li{
            width: 272px;
            text-align: center;
            @include BP_tbsp{
                @include VW(width,272,$tb);
                img{
                    width: 100%;
                }
            }
        }
    }
    &-btn{
        width: 250px;
        margin: 75px auto 0;
        text-align: center;
         @include BP_tbsp{
            @include VW(margin-top,75,$tb);
         }
        a{
            font-size: 16px;
            padding: 30px 0;
            display: block;
        @include BP_tbsp{
            @include VW(padding-top,30,$tb);
            @include VW(padding-bottom,30,$tb);
         }
        @include w_sp{
            @include VW(padding-top,30);
            @include VW(padding-bottom,30);

         }

        }
    }
    &-text,
    &-mintitle{
        text-align: center;
    }
    &-mintitle{
        margin-top: 30px;
        font-size: 26px;
        font-weight: 400;
        @include BP_tbsp{
            @include VW(margin-top,30,$tb);
            font-size: 1.5rem;
        }

        @include w_sp{
            font-size: 1.1rem;
             @include VW(margin-top,30);
        }

    }
    &-text{
        margin-top: 10px;
        font-size: 18px;
        font-weight: 200;
        @include BP_tbsp{
            @include VW(margin-top,10,$tb);
            font-size: 1.3rem;
        }

        @include w_sp{
            font-size: 0.8rem;
             @include VW(margin-top,10);
        }

    }
}