.news-component{
    background-color: #f7f7f7;
    padding: 124px 0;
    @include BP_tbsp{
        @include VW(padding-top,125,$tb);
        @include VW(padding-bottom,125,$tb);

    }

    @include w_sp{
        @include VW(padding-top,125);
        @include VW(padding-bottom,125);
    }

    .area-title{
        &-box{
            text-align: center;
            @include BP_tbsp{
               img{
                   @include VW(width, 147,$tb);
               }
            }
            @include w_sp{
               img{
                   @include VW(width, 178);
               }
            }

        }
        &-min{
            font-size: 18px;
            color: #000000;
        }
    }
    .news-contents{
        width: 700px;
        margin: 45px auto 0;
        display: flex;
        @include BP_tbsp{
            @include VW(margin-top, 45,$tb);
        }

        @include w_sp{
            @include VW(width,624);
            @include VW(margin-top,45);
        }

        &-image{
            margin-right: 25px;
            @include BP_tbsp{
                @include VW(margin-right, 25,$tb);
            }

            img{
                @include w_sp{
                    @include VW(width,200);
                }

            }
        }
        &-message{
            margin-top: 25px;
            @include BP_tbsp{
                @include VW(margin-top, 25,$tb);
            }


            @include w_sp{
                @include VW(margin-top,0);
                @include VW(margin-left,20);
            }

        }
        &-title{
            font-size: 18px;
            font-weight: 500;
            @include w_sp{
                font-size: 1.1rem;
                line-height: 1.4;
            }

        }
        &-timing,
        &-text{
            margin-top: 15px;
            color: #666666;
        }
        &-text{
            font-size: 14px;
            line-height: 22px;
            @include w_sp{
                font-size: 1rem;
                line-height: 1.2;
            }

        }
        &-timing{
            font-size: 12px;
            @include w_sp{
                font-size: 0.8rem;
            }

        }
    }
}