.modal-article{
        position: fixed;
        top: 0;
        left: 0;
        right: auto;
        bottom: auto;
        z-index: 100;
        width: 100vw;
        height: 100vh;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s;

    &-pict{
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 500;
        transform: translate(-50%,-50%);
        img{
            opacity: 0;
            visibility: hidden;
            transform: scale(1.2) translateY(15%);
            transition: all 0.7s cubic-bezier(0.39, 0.6555, 0.3, 1);
        }
        img.show{
            opacity: 1;
            visibility: visible;
            transform: scale(1) translateY(0);
        }
    }
       &-pict:before{
        content: "";
        position: absolute;
        top: -10px;
        right: -70px;
        width: 50px;
        height: 1px;
        transform: rotate(45deg);
        background-color: #ffffff;
        @include w_sp{
            top: -6.608vw;
            @include VW(right, 0);
            width: 25px;
        }

    }
   &-pict:after{
        content: "";
        position: absolute;
        top: -10px;
        right: -70px;
        width: 50px;
        height: 1px;
        transform: rotate(-45deg);
        background-color: #ffffff;
        @include w_sp{
            top: -6.608vw;
            @include VW(right, 0);
            width: 25px;
        }

    }

}

.modal-article.modalopen{
        opacity: 1;
        visibility: visible;

}
.global-overlay{
    cursor: pointer;
}
.global-overlay:before{
    content: "";
    visibility: visible;
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    width: 100vw;
    z-index: 100;
    top: 0;
    opacity: 1;
    height: 100vh;
}

.modalpict{
    @include w_sp{
        @include VW(width, 600);
    }
}