@-webkit-keyframes sdb {
  0% {
    -webkit-transform: rotate(135deg) translate(-30px, 30px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(135deg) translate(0);
    opacity: 0;
  }
}
@keyframes sdb {
  0% {
    transform: rotate(135deg) translate(-30px, 30px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(135deg) translate(0);
    opacity: 0;
  }
}

.scroll-arrow {
  -webkit-animation: sdb 1.5s cubic-bezier(.785,.135,.15,.86) infinite;
  animation: sdb 1.5s cubic-bezier(.785,.135,.15,.86) infinite;
  box-sizing: border-box;
}