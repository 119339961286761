.lineup-component-title{
    text-align: center;
        @media only screen and (max-width : 1060px){
            img{
                @include VW(width, 208, $tb3);
            }
        }
        @include w_sp{
            img{
                @include VW(width, 246);
            }
        }


}

.lineup-items__ar{
    .lineup-list-items-pict01{
        width: 253px;height: 186px;
        background: url("../img/products/ar/lineup-list-items-pict01.png") no-repeat top center;
         @media only screen and (max-width : 1060px){
             @include VW(width,300,$tb3);
             @include VW(height,186,$tb3);
            background: url("../img/products/ar/lineup-list-items-pict01.png") no-repeat top center;
             background-size: 100%;
         }
        @include w_sp{
            @include VW(width,379);
            @include VW(height, 278);
            background: url("../img/products/ar/lineup-list-items-pict01_sp.png") no-repeat top center;
            background-size: 100%;
            margin: auto;
        }
    }
    .lineup-list-items-pict02{
        width: 251px;height: 263px;
        background: url("../img/products/ar/lineup-list-items-pict02.png") no-repeat top center;
        @media only screen and (max-width : 1060px){
            @include VW(width,251,$tb3);
            @include VW(height,263,$tb3);
            background: url("../img/products/ar/lineup-list-items-pict02.png") no-repeat top center;
            background-size: 100%;
         }

        @include w_sp{
            @include VW(width,379);
            @include VW(height, 395);

            background: url("../img/products/ar/lineup-list-items-pict02_sp.png") no-repeat top center;
            background-size: 100%;
            margin: auto;
        }

    }
    .lineup-list-items-pict03{
        width: 257px;height: 344px;
        background: url("../img/products/ar/lineup-list-items-pict03.png") no-repeat top center;
        @media only screen and (max-width : 1060px){
            @include VW(width,251,$tb3);
            @include VW(height,344,$tb3);
            background: url("../img/products/ar/lineup-list-items-pict03.png") no-repeat top center;
            background-size: 100%;
         }

        @include w_sp{
            @include VW(width,386);
            @include VW(height, 519);

            background: url("../img/products/ar/lineup-list-items-pict03_sp.png") no-repeat top center;
            background-size: cover;
            margin: auto;
        }

    }
    .lineup-list-items-pict04{
        width: 153px;height: 316px;
        background: url("../img/products/ar/lineup-list-items-pict04.png") no-repeat top center;
        @media only screen and (max-width : 1060px){
            @include VW(width,153,$tb3);
            @include VW(height,316,$tb3);
            background: url("../img/products/ar/lineup-list-items-pict04.png") no-repeat top center;
            background-size: 100%;
         }

        @include w_sp{
            @include VW(width,237);
            @include VW(height, 489);

            background: url("../img/products/ar/lineup-list-items-pict04_sp.png") no-repeat top center;
            background-size: cover;
            margin: auto;
        }

    }
    .lineup-list-items-pict05{
        width: 457px;height: 217px;
        background: url("../img/products/ar/lineup-list-items-pict05.png") no-repeat top center;
        @media only screen and (max-width : 1060px){
            @include VW(width,457,$tb3);
            @include VW(height,217,$tb3);
            background: url("../img/products/ar/lineup-list-items-pict05.png") no-repeat top center;
            background-size: 100%;
         }

        @include w_sp{
            @include VW(width,570);
            @include VW(height, 274);

            background: url("../img/products/ar/lineup-list-items-pict05_sp.png") no-repeat top center;
            background-size: cover;
            margin: auto;
        }

    }
}
.lineup-component{
    padding-top: 120px;
    padding-bottom: 120px;
    @media only screen and (max-width : 1060px){
        @include VW(padding-top, 120,$tb3);
        @include VW(padding-bottom, 120,$tb3);
    }
    @include w_sp{
        @include VW(padding-top, 100);
        @include VW(padding-bottom, 100);
    }
}
.lineup-items{
    width: 900px;
    margin: 55px auto;
    padding-bottom: 70px;
    border-bottom: 1px solid #cccccc;
    @media only screen and (max-width : 1060px){
        @include VW(width, 900,$tb3);
        @include VW(margin-top, 55,$tb3);
        @include VW(margin-bottom, 55,$tb3);
        @include VW(padding-bottom, 70,$tb3);
    }
    @include w_sp{
        width: 80%;
    }
    .component-btn {
        width: 234px;
        background-color: #118c4b;
         @include w_sp{
             @include VW(width, 460);
         }
        a {
            color: #ffffff;
            padding-top: 15px;
            padding-bottom: 15px;
            font-size: 18px;
            @include w_sp{
                font-size: 1.2rem;
                @include VW(padding-top, 30);
                @include VW(padding-bottom, 30);
            }

        }
    }
}
.lineup-items.lineup-items-parts__ar{
    border-bottom:none;
}
.lineup-items-inner{
    display: flex;
    align-items: center;
    width: 600px;
    margin: auto;
    @media only screen and (max-width : 1060px){
        @include VW(width, 600, $tb3);
    }
     @include w_sp{
         display: block;
          width: 80%;
     }
    .lineup-list-items{
        &-textbox{
            margin-left: 65px;
            @include w_sp{
                 margin-left: 0;
            }
        }
        &-title{
            font-size: 28px;
            font-weight: 400;
            @include w_sp{
                @include VW(margin-top, 30);
                font-size: 1.5rem;
                text-align: center;
            }

        }
        &-price{
            font-size: 20px;
            margin-top: 15px;
            margin-bottom: 20px;
            @include w_sp{
                font-size: 1.2rem;
                text-align: center;
                @include VW(margin-top, 30);
                @include VW(margin-bottom, 50);
            }

        }
        &-text{
            font-size: 14px;
            margin-top: 10px;
            @include w_sp{
                font-size: 1rem;
                @include VW(margin-top, 20);
            }

        }
    }

}

.lineup-items-parts__ar{
    .lineup-items-inner{
        align-items: inherit;
        width: 770px;
        justify-content: space-between;
        @media only screen and (max-width : 1060px){
            @include VW(width, 770, $tb3);
        }
        @include w_sp{
            width: 100%;
        }
        .lineup-list-items:last-child{
            @include w_sp{
                @include VW(margin-top, 130);
            }
        }
        .lineup-items-parts-title{
            text-align: center;
            font-size: 28px;
            @include w_sp{
                font-size: 1.4rem;
            }

        }
        .lineup-list-items-pict04{
            margin-top: 40px;
            @media only screen and (max-width : 1060px){
                 @include VW(margin-top, 40, $tb3);
            }

        }
        .lineup-list-items-pict05{
            margin-top: 90px;
            @media only screen and (max-width : 1060px){
                 @include VW(margin-top, 90, $tb3);
            }
            @include w_sp{
                @include VW(margin-top, 45);
            }
        }
    }
}

.lineup-info-text{
    text-align: center;
    font-size: 18px;
    margin-top: 55px;
    @media only screen and (max-width : 1060px){
         @include VW(margin-top, 55, $tb3);
    }
    @include w_sp{
        font-size: 1.1rem;
        line-height: 1.6;
        @include VW(margin-top, 60);
    }

}
.lineup-info-portion{
    color: #118c4b;
    text-decoration: underline #118c4b;
}

.lineup_product_specification{
    width: 1060px;
    margin: auto;
    @media only screen and (max-width : 1060px){
        width: 90%;
        .lineup_product-title{
            img{
               width: 88.34vw;
            }
        }

    }
    @include w_sp{
        width: 90%;
        .lineup_product-title{
            img{
                width: 87.231vw;
            }
        }
    }
}
.lineup-product{
    &-box{
        display: table;
        width: 1060px;
        margin: 80px auto 0;
        border: 1px solid #666666;
        font-size: 0;
        @media only screen and (max-width : 1060px){
            width: 88.34vw;
            @include VW(margin-top, 80, $tb3);
        }
        @include w_sp{
            width: 90%;
            border: none;
        }

        li{
            display: table-cell;
            width: 20%;
            vertical-align: top;
            @include w_sp{
                display: inline-block;
                width: 50%;
            }
        }
        dl{
            padding-top: 25px;
            padding-bottom: 25px;
            padding-left: 20px;
            border-left: 1px solid #666666;
            @include w_sp{
                border-top: 1px solid #666666;
                @include VW(padding-top, 35);
                @include VW(padding-bottom, 35);
                @include VW(padding-left, 30);
                box-sizing: border-box;
            }

            dt:first-of-type{
                font-size: 15px;
                font-weight: 500;
                @include w_sp{
                    font-size: 0.9rem;
                }

            }
            dd:last-of-type{
                font-size: 14px;
                margin-top: 15px;
                @include w_sp{
                    font-size: 0.7rem;
                    @include VW(margin-top, 15);
                }

            }
        }
        sup {
            vertical-align: top;
            font-size: 75%;
            line-height: 1;
        }
        .css_sp_only {
            display: none;
            @include w_sp{
                display: inline-block;
            }
        }
        li:first-of-type dl{
            border-left: none;
            @include w_sp{
                border-left: 1px solid #666666;
            }

        }
        li:nth-of-type(2) dl,li:nth-of-type(4) dl,li:nth-of-type(5) dl{
            @include w_sp{
                border-right: 1px solid #666666;
            }

        }
        li:nth-of-type(3),li:nth-of-type(4) dl,li:nth-of-type(5) dl{
            @include w_sp{
                border-bottom: 1px solid #666666;
            }

        }
        li:nth-of-type(5) dl{
            @include w_sp{
                border-top: none;
                border-right: none;
            }

        }
        li:first-of-type dl{
            border-left: none;
            @include w_sp{
                border-left: 1px solid #666666;
            }

        }
        li:last-of-type dl{
            @include w_sp{
                border-right: none;
                border-bottom: none;
                border-left: 1px solid #666666;
                border-top: none;
            }

        }
    }
    &-annotation{
        margin-top: 30px;
        @include w_sp{
            width: 90%;
            @include VW(margin-top, 60);
            margin-left: auto;
            margin-right: auto;
        }

        .text{
            padding-left: 10px;
            @include w_sp{
                @include VW(padding-left, 10);
            }

        }
        .text01{
            padding-left: 20px;
            @include w_sp{
                @include VW(padding-left, 20);
            }

        }
        p{
            margin-top: 10px;
            font-size: 12px;
            @include w_sp{
                @include VW(margin-top, 25);
                font-size: 1rem;
                line-height: 1.6;
            }

        }
        p:first-of-type{
            margin-top: 0;
        }
    }
    &-descriptionbox{
        margin-top: 70px;
        display: flex;
         @include w_sp{
             display: block;
         }
    }
    &-description{
            p:first-of-type{
                font-size: 15px;
                font-weight: 500;
                @include w_sp{
                    font-size: 1.1rem;
                }
            }
            p:last-of-type{
                font-size: 14px;
                margin-top: 15px;
                line-height: 1.6;
                 @include w_sp{
                     @include VW(margin-top,20);
                 }
            }
        @include w_sp{
             width: 90%;
             margin-right: auto;
             margin-left: auto;
         }
    }
    &-description:first-of-type{
        width: 500px;
        margin-right: 90px;
        @include w_sp{
             width: 90%;
             @include VW(margin-top,30);
             margin-right: auto;
             margin-left: auto;
         }

    }
    &-description:last-of-type{
        @include w_sp{
             @include VW(margin-top,30);
         }
    }
}