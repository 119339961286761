section{
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
        *{
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s ease-out;
    }
}
section.showparallax{
    opacity: 1;
    visibility: visible;
        *{
        opacity: 1;
        visibility: visible;
    }
    .products-inner-item-pict02:before,
    .products-inner-item-pict03:before,
    .products-inner-item-pict04:before{
        opacity: 1;
        transform: translateY(0);
    }
}

section.showparallax:before{
    opacity: 1;
    transform: scale(1);
}
.effect_wrp{
    overflow: hidden;
    position: relative;
}
.effect_box {
    overflow: hidden;
    opacity: 0;
    background-color: transparent;
}

.story-component-mainpict.act:before{
    -webkit-animation: efc-bgColour01 1.2s ease;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: efc-bgColour01 1.2s ease;
}
@-webkit-keyframes efc-bgColour01 { 0% { width:0;} 60% { width:100%;} 61% { left:0%;} 100% { left:150%;} }
@keyframes efc-bgColour01 { 0% { width:0;} 60% { width:100%;} 61% { left:0%;} 100% { left:150%;} }
