.usersvoice-component{
    background-color: #f7f7f7;
    padding-top: 110px;
    padding-bottom: 150px;
     @media only screen and (max-width : 1060px){
            @include VW(padding-top, 110,$tb3);
            @include VW(padding-bottom, 150,$tb3);
     }
        @include w_sp{
            @include VW(padding-top, 110);
            @include VW(padding-bottom, 100);
        }

        .component-inner{
            width: 1060px;
            margin: auto;
            @include w_sp{
                width: 90%;
            }
            @media only screen and (max-width : 1060px) {
                width: 90%;
            }

        }
        &-title{
            text-align: center;
            @media only screen and (max-width : 1060px) {
                img{
                     @include VW(width, 373,$tb3);
                }
            }

            @include w_sp{
                img{
                    @include VW(width, 441);
                }
            }
        }
    }
    .usersvoice{
        &-voice01{
            margin-top: 70px;
             @media only screen and (max-width : 1060px) {
                @include VW(margin-top, 70,$tb3);
             }
             @include w_sp{
                 @include VW(margin-top, 90);
             }
        }
        @media only screen and (max-width : 1060px) {
            &-subtitle{
                img{
                    width: 88.34vw;
                }
            }
        }

        @include w_sp{
            &-subtitle{
                img{
                    width: 87.231vw;
                }
            }
        }

        &-voice02{
            margin-top: 100px;
            @include w_sp{
                @include VW(margin-top, 100);
            }

        }
        &-box{
            width: 900px;
            margin: 70px auto 0;
            @media only screen and (max-width : 1060px){
                @include VW(margin-top, 70,$tb3);
                @include VW(margin-bottom, 70,$tb3);
                @include VW(width, 900,$tb3);
            }
            @include w_sp{
                width: 90%;
                @include VW(margin-top, 70);
                @include VW(margin-bottom, 70);
            }
        }
        &-title{
            font-size: 18px;
            font-weight: bold;
            @include w_sp{
                font-size: 1.3rem;
                line-height: 1.6;
            }
        }
        &-text{
            font-size: 14px;
            line-height: 2;
            margin-top: 25px;
            @include w_sp{
                font-size: 0.9rem;
            }

        }
    }

    .usersvoice-expert-mintitle {
        text-align: center;
        margin-top: 40px;
        font-size: 16px;
        @include w_sp{
             @include VW(margin-top, 50);
            font-size: 1.2rem;
        }

        span:first-child {
            padding-left: 16px;
        }
        span:last-child {
            padding-left: 16px;
        }
    }