@charset "UTF-8";



// Base Layout
$min : 320; // スマホ最小幅
$sp : 750; // スマホPSDサイズ
$spBP : 767; // スマホ - タブレットサイズ分
$tb : 1200; // タブレット - PCサイズ分
$wpc : 1280; // 幅広PCサイズ分
$tb2 : 900; // 幅広PCサイズ分
$tb3 : 1060; // 幅広PCサイズ分
$tb4 : 1226; // 幅広PCサイズ分


// Base Fontsize
$Fz_root : 10; // html {font-size: 62.5%} を想定
$Fz_sp : 15; // スマホ本文サイズ
$Fz_tb : 14; // タブレット本文サイズ
$Fz_pc : 14; // PC本文サイズ


$Title_color : #043968;

@mixin centering-elements($horizontal: true, $vertical: true) {
  position: absolute;
  @if $horizontal and $vertical {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if $horizontal {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if $vertical {
    top: 50%;
    transform: translate(0, -50%);
  }
}

@mixin absPosition ($top: auto, $right: auto, $bottom: auto, $left: auto) {
	position: absolute;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
 }

 @mixin paddinGood ($wid,$top: 0, $bottom: 0, $left: 0 ,$right: 0) {
	padding-top: ($top / $wid*100)#{'%'};
	padding-right: ($right / $wid*100)#{'%'};
	padding-bottom: ($bottom / $wid*100)#{'%'};
	padding-left: ($left / $wid*100)#{'%'};
 }

 @mixin marginUeShita ($top: 0, $bottom: 0) {
	margin-top: ($top / $sp*100) + vw;
	margin-bottom: ($bottom / $sp*100) + vw;
 }

 @mixin marginPercent($mTop:0, $mRight:0, $mBottom:0, $mLeft:0){
	margin : ((($mTop / $sp) * 100vw)) ((($mBottom / $sp) * 100vw)) ((($mRight / $sp) * 100vw)) ((($mLeft / $sp) * 100vw));
}

 @mixin fontsize($size: 24, $base: 16) {
  font-size: ($size / $base) * 1rem;
}

@mixin lineHeight($base, $size) {
  line-height: ($size / $base);
}

@mixin contwidth($size) {
  width: ($size / $sp *100) + vw;
}

@mixin Width($size,$parentW){
	width: ($size / $parentW) * 100#{'%'};
}

@mixin Height($size,$parentW){
	height: ($size / $parentW) * 100#{'%'};
}

@mixin marginjoge ($sp_window,$top: 0, $bottom: 0 , $left:  auto, $right: auto) {
	margin-top: floor($top / $sp_window*100)#{'%'};
	margin-bottom: floor($bottom / $sp_window*100)#{'%'};
	margin-left: $left;
	margin-right: $right;
 }

// Break Point
@mixin w_sp { // スマホ 0px 〜 spBPで設定した値以下
  @media only screen and (max-width : $sp + "px") {
    @content;
  }
}

@mixin BP_tbsp { // スマホ 0px 〜 tbで設定した値以下
  @media only screen and (max-width : $tb + "px") {
    @content;
  }
}

@mixin BP_tb { // タブレット spBPで設定した値より大きい 〜 tbで設定した値以下
  @media only screen and (min-width : $spBP + 1 + "px") and (max-width : $tb + "px") {
    @content;
  }
}

@mixin BP_pc { // PC tbで設定した値より大きい
  @media print , screen and (min-width : $tb + 1 + "px") {
    @content;
  }
}

@mixin BP_tbnallowpc { // タブレット 〜 PC 幅広でない場合のみ
  @media print , screen and (min-width : $spBP + 1 + "px") and (max-width : $wpc + "px") {
    @content;
  }
}

@mixin BP_nallowpc { // PC 幅広でない場合のみ
  @media print , screen and (min-width : $tb + 1 + "px") and (max-width : $wpc + "px") {
    @content;
  }
}

@mixin BP_widepc {
  @media screen and (max-width : $wpc + 1 + "px") {
    @content;
  }
}


// Vendor Prefix
@mixin VP($name, $argument) {
  -webkit-#{$name}: #{$argument};
  -ms-#{$name}: #{$argument};
  -moz-#{$name}: #{$argument};
  -o-#{$name}: #{$argument};
  #{$name}: #{$argument};
}


// Font Processer
@mixin FZX($num_pc:$Fz_pc , $num_sp:$Fz_sp , $imp_flag:false) { // vm一括変換 引数省略可
	$imp_stg: '';

  @if $imp_flag == true {
		$imp_stg: ' !important';
	}
	$num_pc: strip_unit($num_pc);

	$num_sp: strip_unit($num_sp);

  font-size: $num_pc * 1px;


//	@include BP_tbnallowpc {
//		@if $imp_flag == true {
//			font-size: $num_pc * 1px + $imp_stg;
//		}
//		@else {
//			font-size: $num_pc * 1px;
//		}
//		font-size: processer_vw($num_pc , $wpc , $imp_flag);
//	}

	@include BP_sp {
		@if $imp_flag == true {
			font-size: $num_sp * 1px + $imp_stg;
		}
		@else {
			font-size: $num_sp * 1px;
		}
		font-size: processer_vw($num_sp , $spBP , $imp_flag);
	}
}

@mixin FZ_vw($size:$Fz_sp , $viewport:$spBP , $imp_flag:false) { // vm変換 引数省略可
  font-size: $size * 1px;
  font-size: processer_vw($size , $viewport , $imp_flag);
}

@mixin FZ_rem($size:10 , $root:10) { // rem変換 引数省略可
  font-size: $size * 1px;
  font-size: ($size / $root) * 1rem;
}

@mixin FZ_em($size:10 , $parent:10) { // em変換 第2引数には親要素のサイズを入力 （省略可）
  font-size: ($size / $parent) * 1em;
}


// Margin Processer
@mixin VW($target , $size , $viewport:$spBP , $imp_flag:false) { // vw変換 第1引数はcss属性 第3引数以降省略可
	$imp_stg: '';
  @if $imp_flag == true {
		$imp_stg: ' !important';
	}
	#{$target}: unquote($size * 1px + $imp_stg);
	#{$target}: processer_vw($size , $viewport , $imp_flag);
}






@mixin VWX($target , $num_pc , $num_sp , $imp_flag:false) { // vw一括変換 第1引数はcss属性 第3引数以降省略可
	$imp_stg: '';


  @if $imp_flag == true {
		$imp_stg: ' !important';
	}
	$num_pc_len: length($num_pc);

	$num_sp_len: length($num_sp);

	@if $num_pc_len == 1 {
		$num_pc: strip_unit($num_pc);

		@if type-of($num_pc) == 'number' {
			#{$target}: unquote($num_pc * 1px + $imp_stg);
		}
		@else {
			#{$target}: unquote($num_pc + $imp_stg);
		}

//		@include BP_tbnallowpc {
//			@if $imp_flag == true {
//				#{$target}: unquote($num_pc * 1px + $imp_stg);
//			}
//			@else {
//				#{$target}: unquote($num_pc * 1px);
//			}
//			#{$target}: processer_vw($num_pc , $wpc , $imp_flag);
//		}
	}
	@else {
		$num_all: '';
		@each $item in $num_pc {
			$num: strip_unit($item);
			@if $num == 0 {
				$num_all: $num_all + $num + ' ';
			}
			@elseif type-of($num) != 'number' {
				$num_all: $num_all + $num + ' ';
			}
			@else {
				$num_all: unquote($num_all + $num * 1px + ' ');
			}
		}
		#{$target}: $num_all + $imp_stg;
//		@include BP_tbnallowpc {
//			$num_all: '';
//			$num_all_vw: '';
//			@each $item in $num_pc {
//				$num: strip_unit($item);
//				@if $num == 0 {
//					$num_all: $num_all + $num + ' ';
//					$num_all_vw: $num_all_vw + $num + ' ';
//				}
//
//
//				@else {
//					$num_all: unquote($num_all + $num * 1px + ' ');
//					$num_all_vw: unquote($num_all_vw + processer_vw($num , $wpc , '') + ' ');
//				}
//			}
//			#{$target}: $num_all + $imp_stg;
//
//			#{$target}: $num_all_vw + $imp_stg;
//		}
	}

  @if $num_sp_len == 1 {
		$num_sp: strip_unit($num_sp);
		@include BP_sp {
			@if type-of($num_sp) == 'number' {
				#{$target}: unquote($num_sp * 1px + $imp_stg);
			}
			@else {
				#{$target}: unquote($num_sp + $imp_stg);
			}
			#{$target}: processer_vw($num_sp , $sp , $imp_flag);
		}
	}
	@else {
		@include BP_sp {
			$num_all: '';
			$num_all_vw: '';
			@each $item in $num_sp {
				$num: strip_unit($item);
				@if $num == 0 {
					$num_all: $num_all + $num + ' ';
					$num_all_vw: $num_all_vw + $num + ' ';
				}
				@elseif type-of($num) != 'number' {
					$num_all: $num_all + $num + ' ';
					$num_all_vw: $num_all_vw + $num + ' ';
				}
				@else {
					$num_all: unquote($num_all + $num * 1px + ' ');

					$num_all_vw: unquote($num_all_vw + processer_vw($num , $sp , '') + ' ');

				}
			}
			#{$target}: $num_all + $imp_stg;
			#{$target}: $num_all_vw + $imp_stg;

		}
	}
}


@mixin TRX($target , $num_pc , $num_sp , $imp_flag:false) { // スループット 第1引数はcss属性 第3引数以降省略可
	$imp_stg: '';
  @if $imp_flag == true {
		$imp_stg: ' !important';
	}
	#{$target}: $num_pc + $imp_stg;
	@include BP_sp {
		#{$target}: $num_sp + $imp_stg;
	}
}

// Indent
@mixin Indent($em_n:1) { // 頭出しの汎用インデント 引数省略で1em
	padding-left: $em_n * 1em;
	text-indent: (0 - $em_n) * 1em;
}


// Text transfar
@mixin txt_on() { // テキスト飛ばし
	display: inline-block;
	white-space: nowrap;
	text-indent: 300%;
	overflow: hidden;
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: contain;
}
@mixin txt_off() { // テキスト飛ばし解除
	display: inline-block;
	white-space: normal;
	text-indent: 0;
	overflow: visible;


	background-image: none !important;
}


// Image transfar
@mixin imt_on() { // imgタグ飛ばし
	display: inline-block;
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: contain;
	img {
		display: none;
	}
}
@mixin imt_off() { // imgタグ飛ばし解除
	background-image: none !important;
	img {
		display: inline;
	}
}


// Hover Opacity
@mixin H_opacity($opa:0.7) { // ホバー時の透過設定 引数省略で0.7
	transition: opacity 0.35s ease;
	&:hover {
		opacity: $opa;
	}
}


// List
@mixin Plane_list {
	margin: 0;
	padding: 0;
	list-style: none;
}

// Basically Before & After Format
@mixin BA($pos:absolute) { // Before , After基本セット 引数省略ならposition: absolute;
	content: "";
	display: inline-block;
	position: $pos;
	width: auto;
	height: auto;
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: contain;
}


// clearfix
@mixin CF($flag:false) {
	& {
		*zoom: 1;
		&:after {
			content: "";

			display: table;
			clear: both;
		}
	}











	@if $flag == pc {
		@include BP_tbsp {
			&:after {
				display: none;
			}
		}
	}
	@else if $flag == pctb {
		@include BP_sp {
			&:after {
				display: none;
			}
		}
	}
}


// Processer
@function processer_vw($font_size , $viewport_size , $imp) { // vm 出力計算コア
  $rate: 100 / $viewport_size;
	$imp_stg: '';
  @if $imp == true {
		$imp_stg: ' !important';
	}
  @return unquote(($rate * $font_size * 1vw) + $imp_stg);
}

@function strip_unit($number) { // 数値から単位を脱がせる
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}
