
body,html{
    width: 100%;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    font-size: 100%;

    font-family: "Noto Sans Japanese",san-serif;
    .wrapper{
        width: 100%;
        height: 100%;
    }
}

body.scrollstop, html.scrollstop {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.sp_obj{
    display: none !important;
}
.pc_obj{
    display: block !important;
}

@include w_sp{
    .sp_obj{
    display: block !important;
    }
    .pc_obj{
        display: none !important;
    }
}

header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 25px;
    padding-bottom: 35px;
    position: fixed;
    top: 0;left: 0;
    @include w_sp{
        margin-top: 0;
        padding-top: 0;
        padding-bottom: 4vw;
    }

    z-index: 60;

    .site-title{
        margin-left: 40px;
        position: relative;
        top: 0;left: 0;
        @include w_sp{
            @include VW(margin-top,30);
            @include VW(margin-left,30);
        }
        img{
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.5s;
            position: absolute;
            top: 0;left: 0;
            @include w_sp{
                width: 32.12vw;
            }
        }
    }
    .site-title.menu-open{
        display: block;
        z-index: 2;
        img.open{
            opacity: 1;
            visibility: visible;
        }
    }
    .navi-box{
        margin-right: 40px;
        margin-top: 5px;
        @include w_sp{
            margin-right: 0;
            margin-top: 28.557vw;
        }
    }
    .navi-box-list{
        display: flex;

        li{
            margin-right: 30px;
            display: none;
            cursor: pointer;
             @include w_sp{
                 display: block;
             }
        }
        li:last-child{
            margin-right: 0;
        }
        @include w_sp{
           display: block;
            @include VW(width,320);
            margin: auto;
           li {
               margin-right: 0;
               @include VW(margin-top,90);
               img {
                   @include VW(width,320);
               }
           }
           li:first-child{
               margin-top: 0;
           }
        }

    }
    .navi-box-list.act{
        li{
            display: block;
        }
    }



@include w_sp{
    .navi-drawer {
    position: relative;
    @include VW(margin-right, 30);
    @include VW(margin-top, 30);
}


/*アイコンのスペース*/
.navi-close,
.navi-open {
    display: inline-block;
    width: 40px;
    height: 40px;
    vertical-align: middle;
    @include w_sp{
        width: 8.215vw;
        height: 7.215vw;
        position: relative;
        z-index: 50;
    }
}

/*ハンバーガーアイコンをCSSだけで表現*/
.navi-open.none,
.navi-close.none{
    visibility: hidden;
    opacity: 0;
    width: 0;
}
.navi-close span, .navi-close span:before, .navi-close span:after,
.navi-open span, .navi-open span:before, .navi-open span:after {
    position: absolute;
    height: 3px;/*線の太さ*/
    width: 40px;/*長さ*/
    display: block;
    content: '';
    cursor: pointer;
    @include w_sp{
        width: 8.215vw;
    }
}
.navi-open span, .navi-open span:before, .navi-open span:after {
    background: #ffffff;
}
.navi-close span, .navi-close span:before, .navi-close span:after{
    background: #000000;
}
.navi-close span:before,
.navi-open span:before {
    bottom: -12px;
}
.navi-close span:after,
.navi-open span:after {
    bottom: -24px;
}
.navi-close.toggle span:before{
    visibility: hidden;
}
.navi-close.toggle span{
    transform: rotate(45deg);
    top: 11px;
}
.navi-close.toggle span:after{
    transform: rotate(-90deg);
    bottom: 0;
}

/*閉じる用の薄黒カバー*/

/*中身*/
.navi{
    &-content{
        overflow: auto;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        transition: all .3s ease-in-out;
    }
    &-content.menu-open{
        opacity: 0;
        visibility: hidden;
    }
    &-content.menu-open.naviopen{
        opacity: 1;
        visibility: visible;
        background: #fff;
    }
}
}


}



// 各ページ共通
.pages-keyvisual{
    &-content{
        position: relative;
        top: 0;left: 0;
    }
    &{
        figure.products-main-bg{
            width: 100%;
            height: 530px;
            background: url("../img/products/main-pict.jpg") no-repeat top center;
            background-size: cover;
            @include w_sp{
                 @include VW(height, 550);
                 background: url("../img/products/main-pict_sp.jpg") no-repeat top center;
                 background-size: cover;
             }
        }
        figure.products-inner-main-bg{
            width: 100%;
            height: 791px;
            background: url("../img/products/ar/main-pict.png") no-repeat top center;
            background-color: #000000;
            background-size: cover;
            @media only screen and (max-width : 1060px){
                background: url("../img/products/ar/main-pict.png") no-repeat top center;
                @include VW(height, 791,$tb3);
                background-color: #000000;
                background-size: cover;
            }
            @include w_sp{
                 @include VW(height, 790);
                 background: url("../img/products/ar/main-pict_sp.png") no-repeat top center;
                 background-color: #000000;
                 background-size: cover;
             }

        }
        figure.aluminum-main-bg{
            width: 100%;
            height: 530px;
            background: url("../img/aluminum/main-pict.jpg") no-repeat top center;
            background-size: cover;
            @include w_sp{
                 @include VW(height, 550);
                 background: url("../img/aluminum/main-pict_sp.jpg") no-repeat top center;
                 background-size: cover;
             }

        }
        figure.story-main-bg{
            width: 100%;
            height: 530px;
            background: url("../img/story/main-pict.jpg") no-repeat top center;
            background-size: cover;
             @include w_sp{
                 @include VW(height, 550);
                 background: url("../img/story/main-pict_sp.jpg") no-repeat top center;
                 background-size: cover;
             }
        }
        figure.faq-main-bg{
            width: 100%;
            height: 380px;
            background-color: #ffffff;
             @include w_sp{
                 @include VW(height, 550);
             }
        }
    }
    &-title{
        position: absolute;
        top: 50%;left: 50%;
        transform: translate(-50%,-50%);
    }
    &-content--products-inner{
        .pages-keyvisual-title{
            -webkit-transform: translate(-50%, -85%);
            transform: translate(-50%, -85%);
            @include w_sp{
                -webkit-transform: translate(-50%, -100%);
                transform: translate(-50%, -100%);
            }

        }
        .pages-keyvisual-maintitle{
            text-align: center;
        }
        .pages-keyvisual-subtitle{
            margin-top: 70px;
            @media only screen and (max-width : 1060px){
                @include VW(margin-top, 70, $tb3);
            }
            @include w_sp{
                @include VW(margin-top, 60);
            }

            img{
                @media only screen and (max-width : 1060px){
                     @include VW(width, 843, $tb3);
                }
                @include w_sp{
                    @include VW(width, 590);
                }
            }


        }
    }
}


footer{
    background-color: black;
    .footer-contact{

        &-box{
            padding: 105px 0;
            background: url("../img/common/footer-bg_pc.jpg") no-repeat center center;
            background-size: cover;
            @include BP_tbsp{
                @include VW(padding-top,105,$tb);
                @include VW(padding-bottom,105,$tb);
            }

            @include w_sp{
                @include VW(padding-top,105);
                @include VW(padding-bottom,105);
                 background: url("../img/common/footer-bg_sp.jpg") no-repeat center center;
                 background-size: cover;
             }

        }
        &-title{
            text-align: center;
            img{
                @include w_sp{
                    @include VW(width,360);
                }
            }
        }
        &-text{
            margin-top: 25px;
            @include w_sp{
                text-align: center;
                @include VW(margin-top,25);
            }

            img{
                @include w_sp{
                    @include VW(width,480);
                }
            }

        }
        &-inner{
            width: 980px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include BP_tbsp{
                width: 90%;
            }
            @include w_sp{
                display: block;
                @include VW(width,640);
            }
        }
        &-right{
            @include w_sp{
                @include VW(margin-top,70);
            }

            .icon-catalog,
            .icon-mail{
                display: inline-block;
                position: relative;
                top: 0;left: 0;
            }
            .icon-catalog:before{
                content: "";
                position: absolute;
                top: 50%;left: -50px;
                width: 25px;
                height: 22px;
                margin-top: -17px;
                background: url("../img/common/icon-catalog.png") no-repeat;

                @include w_sp{
                    @include VW(left, -70);
                    @include VW(width, 50);
                    @include VW(height, 43);
                    background: url("../img/common/icon-catalog.png") no-repeat;
                    background-size: 100%;
                    @include VW(margin-top, -35);
                }

            }
            .icon-mail:before{
                content: "";
                position: absolute;
                top: 50%;left: -50px;
                width: 30px;
                height: 22px;
                margin-top: -17px;
                transition: all 0.5s;
                background: url("../img/common/icon-mail.png") no-repeat;
                @include w_sp{
                    @include VW(left, -70);
                    @include VW(width, 50);
                    @include VW(height, 43);
                    background: url("../img/common/icon-mail.png") no-repeat;
                    background-size: 100%;
                    @include VW(margin-top, -30);
                }

            }

        }
        &-btn{
            border: 1px solid #ffffff;
            width: 537px;
            @include BP_tbsp{
               width: 43vw;
            }

            @include w_sp{
                @include VW(width,640);
                overflow: hidden;
            }

            a{
                display: block;
                color: #ffffff;
                font-size: 22px;
                text-align: center;
                padding-top: 30px;
                padding-bottom:30px;
                padding-left: 50px;

                @include BP_tbsp{
                    font-size: 1rem;
                    @include VW(padding-top,30,$tb);
                    @include VW(padding-bottom,30,$tb);
                    @include VW(padding-left,50,$tb);
                }

                @include w_sp{
                    font-size: 4vw;
                    @include VW(width,640);
                    @include VW(padding-left,50);
                    @include VW(padding-top,40);
                    @include VW(padding-bottom,40);

                }

            }
        }
        &-btn:last-child{
            margin-top: 30px;
            @include w_sp{
                @include VW(margin-top,30);
            }

        }

    }



    .footer-company{
        &-box{
            position: relative;
            margin: 0 auto;
            max-width: 1360px;
            text-align: left;
            @include w_sp{
                max-width: 100%;
            }


            color: #ffffff;
        }
        &-title{
            display: table-cell;
            width: 30vw;
            vertical-align: top;
            color: #ffffff;
            @include BP_tbsp{
                display: block;
            }

            @include w_sp{
                display: block;
                @include VW(width, 572);
                margin: 0 auto;
                img{
                    @include VW(width, 572);
                }
            }

        }
        &-intro{
            display: table-cell;
            vertical-align: top;
            font-size: 14px;
            color: #ffffff;
            text-align: right;
            min-width: 560px;
            @include BP_tbsp{
                display: block;
                padding-left: 0;
                text-align: left;
                 @include VW(margin-top, 42,$tb);
            }

            @include w_sp{
                display: block;
                min-width: auto;
                width: 100%;
                font-size: 0.8rem;
                margin: 0 auto;
                padding-left: 0;
                @include VW(margin-top, 42);
                text-align: left;
            }

        }
        &-intro,&-infor{
            line-height: 1.6;
        }
        &-infor,
        &-copy{
            font-size: 12px;
            color: #ffffff;
            @include w_sp{
                 font-size: 0.8rem;
             }

        }
        &-infor{
            margin-top: 10px;
            @include w_sp{
                @include VW(margin-top, 30);
             }


        }
        &-copy{
            margin-top: 20px;
             @include w_sp{
                 @include VW(margin-top,50);
                 text-align: center;
                 line-height: 1.6;
             }
        }
        &-inner{
            width: 1280px;
            margin: 0 auto;
             padding: 40px 0;
            @include BP_widepc{
                 width: 95%;
            }
            @include w_sp{
                @include VW(width, 676);
            }

        }

    }
}