.story-component{
    position: relative;
    top: 0;left: 0;
    padding-top: 125px;
    padding-bottom: 450px;
    @include BP_tbsp{
        @include VW(padding-top, 125,$tb);
        @include VW(padding-bottom, 450,$tb);
    }
    @include w_sp{
        @include VW(padding-top,125);
        @include VW(padding-bottom,125);
    }

    .component-title{
        img{
            @include BP_tbsp{
                @include VW(width,217,$tb);
            }
            @include w_sp{
                @include VW(width,268);
            }
        }
    }

    &-mainpict{
        position: relative;
        top: 0;left: 0;
        width: 1200px;
        height: 530px;
        overflow: hidden;
        margin: 0 auto;
        display: block;
        background: url("../img/top/story-pict01.jpg") no-repeat center center;
        @include BP_tbsp{
            @include VW(width, 1150,$tb);
            @include VW(height, 530,$tb);
            background-size: cover;
        }
        @include w_sp{
            @include VW(width, 690);
            @include VW(height, 307);
            background-size: cover;

        }
    }
    &-mainpict:before{
        content: "";
        position: absolute;
        top: 0;left: 0;
        background-color: #000000;
        z-index: 1;
        height: 100%;
    }


    &-link{
        width: 1145px;
        height: 100%;
        margin: 85px auto 0;
        position: relative;
        top: 0;left: 0;
        @include BP_tbsp{
            width: 95%;
        }
        @include w_sp{
           @include VW(width, 630);
           @include VW(margin-top, 60);
        }
        &-text{
            margin-top: 60px;
            font-size: 14px;
            font-weight: 200;
            line-height: 32px;

            @include BP_tbsp{
                @include VW(margin-top, 40,$tb);
                position: relative;
                z-index: 10;
            }

            @include w_sp{
                @include VW(margin-top, 25);
                font-size: 0.9rem;
                line-height: 1.8;
            }

        }

        &-pictbox,
        &-textbox{
            display: table-cell;
            vertical-align: top;
            @include w_sp{
                display: block;
            }
        }


        &-textbox{
            padding-top: 89px;
            @include BP_tbsp{
                @include VW(padding-top, 89,$tb);
            }
            @include w_sp{
                @include VW(padding-top, 0);
            }
        }
        &-pictbox{
            position: absolute;
            top: 0;right: 0;
            @include w_sp{
                position: static;
                font-size: 0;
            }
        }
        &-title{
            position: relative;
            z-index: 50;
            @include BP_tbsp{
                img{
                    @include VW(width,508,$tb);
                }

            }

            @include w_sp{
                img{
                    @include VW(width,516);
                }
            }

        }
        .component-btn{
            position: absolute;
            bottom: -130px;
            left: 0;
            @include BP_tbsp{
                @include VW(bottom,-130,$tb);
            }
            @include w_sp{
                position: static;
                @include VW(width, 590);
                @include VW(height, 100);
                @include VW(margin-top, 90);
            }
            a {
                border: 1px solid #000000;
                color: #000000;
                width: 256px;
                @include BP_tbsp{
                    @include VW(width,256,$tb);
                }

                @include w_sp{
                    border: 1px solid #000000;
                    position: static;
                    font-size: 1rem;
                    @include VW(width, 590);
                    @include VW(margin-top, 90);
                }
            }
        }
    }
    &-pict01{
        position: absolute;
        top: 0;right: 0;
        width: 703px;
        height: 412px;
        background: url("../img/top/story-pict02.jpg") no-repeat center center;
        @include BP_tbsp{
            @include VW(width, 703,$tb);
            @include VW(height, 412,$tb);
            background-size: cover;
        }

        @include w_sp{
            position: static;
            @include VW(width, 630);
            @include VW(height, 373);
            background-size: cover;
            @include VW(margin-top, 50);
        }
    }
    &-pict02{
        position: absolute;
        top: 320px;
        right: 510px;

        width: 237px;
        height: 304px;
        background: url("../img/top/story-pict03.jpg") no-repeat center center;
        @include BP_tbsp{
            @include VW(top, 320,$tb);
            @include VW(right, 510,$tb);
            @include VW(width, 237,$tb);
            @include VW(height, 304,$tb);
            background-size: cover;
        }

        @include w_sp{
            position: static;
            display: inline-block;
            @include VW(width, 194);
            @include VW(height, 260);
            background-size: cover;
            @include VW(margin-top, 45);
        }


    }
    &-pict03{
        position: absolute;
        top: 460px;
        right: 70px;
        width: 391px;
        height: 249px;
        background: url("../img/top/story-pict04.jpg") no-repeat center center;
        @include BP_tbsp{
            @include VW(top, 460,$tb);
            @include VW(right, 70,$tb);
            @include VW(width, 391,$tb);
            @include VW(height, 249,$tb);
            background-size: cover;
        }
        @include w_sp{
            position: static;
            display: inline-block;
            @include VW(width, 403);
            @include VW(height, 260);
            background-size: cover;
            @include VW(margin-left, 30);
        }


    }
}
.story-component:before{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;left: 0;
    background: url("../img/top/story-bg_pc.jpg") no-repeat center center;
    @include w_sp{
        background: url("../img/top/story-bg_sp.jpg") no-repeat center center;
        background-size: cover;
    }

    opacity: 0;
    transform: scale(1.1);
    transition: all 0.7s cubic-bezier(0.39, 0.575, 0.565, 1);
    background-size: cover;
    z-index: -1;
}

.story-pages{
        .ancer-component-left{
        background: url("../img/ancer/ancer-products-items01.png") no-repeat center center;
        background-size: cover;
            @include w_sp{
                background: url("../img/ancer/ancer-products-items01_sp.png") no-repeat center center;
                background-size: cover;
            }

        .ancer-title{
            @include w_sp{
                img{
                    @include VW(width, 290);
                }
            }

        }

    }
    .ancer-component-right{
        background: url("../img/ancer/ancer-aluminum-items01.png") no-repeat center center;
        background-size: cover;
            @include w_sp{
                background: url("../img/ancer/ancer-aluminum-items01_sp.png") no-repeat center center;
                background-size: cover;
            }

        .ancer-title{
            @include w_sp{
                img{
                    @include VW(width, 290);
                }
            }

        }

    }

}

.story-main-title{
        @include w_sp{
            @include VW(width, 275);
    }
}

.story-article{
    &-wrapper{
        background-color: #ffffff;
    }
    &-inner{
        width: 1060px;
        margin: auto;
        @media only screen and (max-width : 1060px) {
            width: 95%;
        }
        @include w_sp{
            width: 85%;
        }


    }
    &-sub-text{
            font-size: 14px;
            text-align: center;
            line-height: 2;
            padding-top: 70px;
            @media only screen and (max-width : 1060px) {
                @include VW(padding-top, 70, $tb3);
            }
            @include w_sp{
                font-size: 1rem;
                text-align: left;
                @include VW(padding-top, 80);
            }
    }
    &-contents{
        margin-top: 70px;
        margin-bottom: 120px;
        display: flex;
        justify-content: space-between;

        position: relative;
        top: 0;left: 0;
        @media only screen and (max-width : 1060px) {
            @include VW(margin-top, 70, $tb3);
            @include VW(margin-bottom, 120, $tb3);
        }
        @include w_sp{
            display: block;
            @include VW(margin-top, 90);
            @include VW(margin-bottom, 100);
        }


    }
    &-contents:before{
        content: "";
        position: absolute;
        top: 50%;left: 50%;
        width: 25px;height: 25px;
        margin-top: -12px;margin-left: -12px;
        background: url("../img/story/story-company-to.png") no-repeat center center;
    }
    @include w_sp{
        &-contents:before{
            content: none;
        }
    }
    &-box{
        width: 480px;
        @media only screen and (max-width : 1060px) {
            @include VW(width, 480, $tb3);
        }
        @include w_sp{
            width: 85%;
            margin: auto;
        }


    }
    &-box:first-child{
        img{
            @media only screen and (max-width : 1060px) {
                @include VW(width, 345, $tb3);
            }
            @include w_sp{
                @include VW(width, 469);
            }

        }
    }
    &-box:last-child{
        @include w_sp{
            @include VW(margin-top, 150);
            position: relative;
        }

        img{
            @media only screen and (max-width : 1060px) {
                @include VW(width, 148, $tb3);
            }
            @include w_sp{
                @include VW(width, 202);
            }

        }
    }
    @include w_sp{
        &-box:last-child:before{
            content: "";
            @include VW(width, 40);
            @include VW(height, 40);
            position: absolute;
            top: -13vw;left: 50%;
            background: url("../img/story/story-company-to_sp.png") no-repeat center center;
            background-size: cover;

        }
    }
    &-company-pict01,
    &-company-pict02{
       width: 480px;
       height: 240px;
        @media only screen and (max-width : 1060px) {
            @include VW(width, 480, $tb3);
            @include VW(height, 240, $tb3);
        }
        @include w_sp{
            @include VW(width, 570);
            @include VW(height, 280);
        }

    }
    &-company-pict01{
        background: url("../img/story/story-company-pict01.png") no-repeat center center;
        @media only screen and (max-width : 1060px) {
            background-size: cover;
        }

    }
    &-company-pict02{
        background: url("../img/story/story-company-pict02.png") no-repeat center center;
        @media only screen and (max-width : 1060px) {
            background-size: cover;
        }

    }
    &-title{
        text-align: center;
        margin-top: 50px;
        margin-bottom: 30px;
        @media only screen and (max-width : 1060px) {
            @include VW(margin-top, 50, $tb3);
            @include VW(margin-bottom, 30, $tb3);
        }
        @include w_sp{
            @include VW(margin-top, 40);
            @include VW(margin-bottom, 40);
        }

    }
    &-text{
        font-size: 14px;
        line-height: 2;
    }
    &-tothedevelopment{
        background: url("../img/story/story-company-development-bg.jpg") no-repeat center center;
        background-size: cover;
        @include w_sp{
            background: url("../img/story/story-company-development-bg_sp.jpg") no-repeat center center;
            background-size: cover;
        }

    }
    &-tothedevelopment-title{
        padding-top:293px;
        padding-bottom:245px;
        width: 1226px;
        margin: auto;
        display: flex;
        justify-content: flex-end;
         @media only screen and (max-width : 1230px) {
            width: 95%;
            @include VW(padding-top, 293, $tb3);
            @include VW(padding-bottom, 245, $tb3);

         }
        @include w_sp{
            width: 100%;
            display: block;
            text-align: center;
            @include VW(padding-top, 300);
            @include VW(padding-bottom, 300);

        }

        img{
            @media only screen and (max-width : 1060px) {
                @include VW(width, 492, $tb3);
            }
            @include w_sp{
                 @include VW(width, 614);
            }
        }
    }
    &-tothedevelopment-box01{
        display: flex;
        position: relative;
        top: 0;left: 0;
        margin-top: 145px;
        @media only screen and (max-width : 1060px) {
            @include VW(margin-top, 145, $tb3);
        }
        @include w_sp{
            display: block;
            @include VW(margin-top, 70);
        }

        .story-article-tothedevelopment-textbox{
            width: 450px;
            font-size: 14px;
            line-height: 2;
            margin-top: 40px;
            margin-right: 80px;
            @media only screen and (max-width : 1060px) {
                @include VW(width, 450, $tb3);
                @include VW(margin-top, 40, $tb3);
                @include VW(margin-right, 40, $tb3);
            }
            @include w_sp{
                width: 100%;
            }


            p{
                margin-top: 30px;
                @media only screen and (max-width : 1060px) {
                    @include VW(margin-top, 30, $tb3);
                }
                @include w_sp{
                    @include VW(margin-top, 40);
                }

            }
            img{
                @media only screen and (max-width : 1060px) {
                    @include VW(width, 406, $tb3);
                }
                @include w_sp{
                    @include VW(width, 545);
                }


            }

        }
    }
    &-tothedevelopment-pictbox01{
        @include w_sp{
            display: table;
            @include VW(margin-top, 65);
        }

    }
    &-tothedevelopment-pict01{
        width: 305px;height: 380px;
        background: url("../img/story/story-article-tothedevelopment-pict01.png") no-repeat center center;
        @media only screen and (max-width : 1060px) {
            @include VW(width, 305, $tb3);
            @include VW(height, 380, $tb3);
            background-size: cover;
        }
        @include w_sp{
            @include VW(width, 380);
            @include VW(height, 470);
            display: table-cell;
        }
    }
    &-tothedevelopment-pict02{
        position: absolute;
        top: -50px;right: 0;

        width: 185px;height: 250px;
        background: url("../img/story/story-article-tothedevelopment-pict02.png") no-repeat center center;
        @media only screen and (max-width : 1060px) {
            @include VW(width, 185, $tb3);
            @include VW(height, 250, $tb3);
            @include VW(top, -50, $tb3);
            background-size: cover;
        }
        @include w_sp{
            @include VW(width, 220);
            @include VW(height, 300);
            top: auto;
            bottom: 22vw;
        }

    }
    &-tothedevelopment-pict03{
        position: absolute;
        bottom: -100px;;right: 35px;

        width: 250px;height: 150px;
        background: url("../img/story/story-article-tothedevelopment-pict03.png") no-repeat center center;
        @media only screen and (max-width : 1060px) {
            @include VW(width, 250, $tb3);
            @include VW(height, 150, $tb3);
            @include VW(bottom, -100, $tb3);
            @include VW(right, 35, $tb3);
            background-size: cover;
        }
        @include w_sp{
            @include VW(width, 220);
            @include VW(height, 140);
            top: auto;
            bottom: 0;
            right: 0;
        }

    }
    &-tothedevelopment-box02{
        display: flex;
        justify-content: space-around;
        flex-flow: row-reverse;
        margin-top: 180px;
        margin-bottom: 140px;
        @media only screen and (max-width : 1060px) {
            @include VW(margin-top, 180, $tb3);
            @include VW(margin-bottom, 140, $tb3);
        }

        @include w_sp{
            display: block;
            @include VW(margin-top, 70);
             @include VW(margin-bottom, 110);
        }

        .story-article-tothedevelopment-textbox{
            margin-top: 50px;
            width: 580px;
            font-size: 14px;
            line-height: 2;
            @media only screen and (max-width : 1060px) {
                @include VW(width, 580, $tb3);
                @include VW(margin-top, 50, $tb3);
            }
            @include w_sp{
                width: 100%;
                @include VW(margin-top, 0);
            }

            p{
                margin-top: 30px;
                @media only screen and (max-width : 1060px) {
                    @include VW(margin-top, 30, $tb3);
                }

            }
            img{
                @media only screen and (max-width : 1060px) {
                    @include VW(width, 518, $tb3);
                }
                @include w_sp{
                    @include VW(width, 391);
                }

            }

        }

    }
    &-tothedevelopment-pict04{
        width: 380px;height: 520px;
        background: url("../img/story/story-article-tothedevelopment-pict04.png") no-repeat center center;
        @media only screen and (max-width : 1060px) {
            @include VW(width, 380, $tb3);
            @include VW(height, 520, $tb3);
            background-size: cover;
        }
        @include w_sp{
            @include VW(width, 380);
            @include VW(height, 516);
            margin-left: auto;
            margin-right: auto;
            @include VW(margin-top, 90);
        }
    }

}