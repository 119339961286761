.gallery-pages-top{
    background-color: black;
}
.gallery-component{
        padding-top: 125px;
        padding-bottom: 140px;
        width: 100%;
        overflow: hidden;
            @include BP_tbsp{
                width: 100%;
                @include VW(padding-top,125,$tb);
                @include VW(padding-bottom,140,$tb);
            }

            @include w_sp{
                @include VW(padding-top,125);
                @include VW(padding-bottom,125);
            }

    .gallery-component-inner{
        width: 100vw;
        margin: 0 auto;
        @include BP_tbsp{
            width: 100%;
        }

    }
    .pict{
        img{
        width: 100%;
        }

    }
    &-title{
        text-align: center;
        margin-bottom: 55px;
        @include BP_tbsp{
            @include VW(margin-bottom,55,$tb);
        }

        @include w_sp{
                @include VW(margin-bottom,65);
        }

        img{
            @include BP_tbsp{
                @include VW(width,241,$tb);
            }
            @include w_sp{
                @include VW(width,295);
            }

        }
    }
    .swiper-button-prev, .swiper-container-rtl .swiper-button-next,
    .swiper-button-next, .swiper-container-rtl .swiper-button-prev{
        background-image: url("../../public/img/common/gallery/arrow.png");
        @include w_sp{
            @include VW(width,24);
            @include VW(height,47);
            background-size: cover;
        }
    }
    .swiper-button-prev, .swiper-container-rtl .swiper-button-next{
        transform: rotate(180deg);
    }
    .swiper-container{
        overflow: inherit;
        @include w_sp{
            overflow: hidden;
            @include VW(height,674);
            @include marginPercent(0,0,60,60);
        }

    }
    .swiper-button-prev {
        left: 22vw;
        @include w_sp{
            left: 0;
        }

    }
    .swiper-button-next {
        right: 22vw;
        @include w_sp{
            right: 0;
        }
    }
    .swiper-button-prev,
    .swiper-button-next{
        margin-top: 2vw;
        @include w_sp{
            margin-top: 0;
            top: auto;
            bottom: 0;
        }
    }
    .swiper-pagination {
        bottom: calc(10px + -4vw);
        @include w_sp{
            bottom: 1vw;
        }
    }
    .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        display: inline-block;
        border-radius: inherit;
        background: #cccccc;
        opacity: 1;

        @include w_sp{
            width: 10px;
            height: 10px;
        }

    }
    .swiper-pagination-bullet-active{
        background: #666666;
    }
    .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet{
        margin: 0 15px 0 0;
        @include w_sp{
            margin-right: 10px;
        }
    }
    .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet:last-child{
        margin: 0;
    }
    .swiper-slide.pict{
        cursor: pointer;
        @include w_sp{
            overflow: hidden;
        }

    }

    .modal-btn{
        position: absolute;
        bottom: 0;right: 0;
        margin-right: 2vw;
        margin-bottom: 2vw;
        cursor: pointer;
        @include w_sp{
           margin-bottom: 0;
           bottom: 36vw;
            width: 4vw;
        }

    }
}

.gallery-pages-products-inner{
    .swiper-button-prev, .swiper-container-rtl .swiper-button-next,
    .swiper-button-next, .swiper-container-rtl .swiper-button-prev{
        background-image: url("../../public/img/products/ar/arrow.png");
        @include w_sp{
            @include VW(width,24);
            @include VW(height,47);
            background-size: cover;
        }
    }

}