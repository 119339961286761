@charset 'UTF-8';

@import "_static/_reset.scss";
@import "_static/_mixin.scss";
@import "_static/_common.scss";
@import "_static/_swiper.scss";

@import "_forms/_layout.scss";
@import "_forms/_news.scss";
@import "_forms/_gallery.scss";
@import "_forms/_products.scss";
@import "_forms/_aluminum.scss";
@import "_forms/_story.scss";
@import "_forms/_faq.scss";
@import "_forms/_usersvoice.scss";
@import "_forms/_lineup.scss";

@import "_anime/_firstview.scss";
@import "_anime/_scroll_btn.scss";
@import "_anime/_modal.scss";
@import "_anime/_btn.scss";
@import "_anime/_displayanime.scss";
@import "_anime/_aos.scss";
@import "_anime/_scroll_header.scss";

main.page-top{
    height: 100%;
}
.main-container.page-top{
    height: 100%;
}
.app-keyvisual-content{
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;left: 0;
    background-color: black;
    overflow: hidden;
    .app-keyvisual-movie{
        width: 100%;
        max-height: 100vh;
        @include w_sp{
            height: 100%;
            max-height: 100%;
        }
        .pages-keyvisual{
            height: 100vh;
            @include w_sp{
                height: 100%;
            }
        }
    }
    figure.top-main-bg{
        @include w_sp{
            width: 100%;
            height: 100%;
            background: url("../img/top/main-pict_sp.jpg") no-repeat top center;
            background-size: cover;
        }
    }

    .app-keyvisual-title{
        position: absolute;
        top: 50%;left: 50%;
        transform: translate(-50%,-50%);
        img{
            @include w_sp{
                @include VW(width,517);
            }
        }
    }

    .iframe-main-move{
        padding: 0;
        margin: 0;
        border:none;
        opacity: 0.6;
        min-height: 100%;
        min-height: 100vh;
        min-width: 100%;
        min-width: 100vw;
        width: auto;
        height: auto;
        @media only screen and (max-width : 1150px){
            width: initial;
            height: 100vh;
        }
         @include w_sp{
            width: 100%;
            height: auto;
         }
    }
}
.app-keyvisual-scroll-induction{
    position: absolute;
    bottom: 70px;left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
        @include w_sp{
            @include VW(bottom,80);
        }
}
.scroll-text{
    font-size: 11px;
    color: #ffffff;
    @include w_sp{
        font-size: 2vw;
    }
}

.scroll-arrow{
    width: 12px;
    height: 12px;
    border: 0px;
    border-top: solid 1px #ffffff;
    border-right: solid 1px #ffffff;
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
    position: absolute;
    bottom: -55px;
    left: 50%;
    margin-left: -6px;
    margin-top: -4px;
    @include w_sp{
        width: 6px;
        height: 6px;
        margin-left: -3px;
        margin-top: -3px;
        bottom: -46px;
    }

}

.app-keyvisual-move-switch{
    position: absolute;
    bottom: 70px;
    right: 40px;
    @include w_sp{
        bottom: 9vw;
        right: 4vw;
    }
    a{
        font-size: 15px;
        color: #ffffff;
        padding-left: 66px;
        padding-right: 50px;
        padding-top: 15px;
        padding-bottom: 15px;
        border: 1px solid #ffffff;
        position: relative;
        top: 0;left: 0;
        @include w_sp{
            font-size: 2vw;
            @include VW(padding-top,20);
            @include VW(padding-bottom,20);
            @include VW(padding-left,60);
            @include VW(padding-right,30);
        }
    }
    a:before{
        content: "";
        position: absolute;
        top: 50%;left: 50%;
        margin-top: -6px;
        margin-left: -57px;
        width: 18px;height: 14px;
        background: url("../img/top/icon-movie.png") no-repeat;
        @include w_sp{
            @include VW(width,28);
            @include VW(height,22);
            @include VW(margin-top,-11);
            @include VW(margin-left,-60);

            background: url("../img/top/icon-movie.png") no-repeat;
            background-size: 100%;
        }

    }
}

.free-component{
    position: relative;
    top: 0;left: 0;
    padding-top: 115px;
    padding-bottom: 680px;
    @include BP_tbsp{
        @include VW(padding-top,115,$tb);
        @include VW(padding-bottom,680,$tb);
    }

        @include w_sp{
            padding-top: 12vw;
            padding-bottom: 59vw;
        }
    background-size: cover;
    .component{
        &-title{
            text-align: center;
            font-size: 40px;
            font-weight: 500;

            @include w_sp{
                font-size: 7vw;
                line-height: 1.6;
            }
            span{
                opacity: 0;
                visibility: hidden;
                transition: opacity 0.2s;
            }
        }

        &-text{
            text-align: center;
            font-size: 14px;
            line-height: 32px;
             @include w_sp{
                 text-align: left;
                 font-size: 0.7rem;
                 @include VW(width,630);
                 margin: auto;
                 line-height: 2;
             }
        }
    }
}
.free-component:before{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;left: 0;
    background: url("../img/top/free-bg_pc.jpg") no-repeat center center;
        @include w_sp{
            background: url("../img/top/free-bg_sp.jpg") no-repeat center center;
            background-size: cover;
        }

    opacity: 0;
    transform: scale(1.1);
    transition: all 0.7s cubic-bezier(0.39, 0.575, 0.565, 1);
    background-size: cover;
    z-index: -1;
}

.ancer-component{
    &-inner{
        width: 100%;
        display: flex;
    }

    &-top,
    &-left,
    &-right{
        width: 50%;
        padding: 105px 0;
        @include w_sp{
            @include VW(padding-top, 80);
            @include VW(padding-bottom, 60);
        }

        .ancer-title{
            text-align: center;

        }
        .products-list-btn{
            margin: 50px auto 0;
            border: 1px solid #ffffff;
            height: 55px;

            @include w_sp{
                @include VW(margin-top, 34);
                @include VW(width, 289);
                @include VW(height, 77);
            }

            a {
                color: #ffffff;
                height: 55px;
                @include w_sp{
                    @include VW(height, 77);
                    font-size: 0.8rem;
                }

            }
        }
    }
}







