    .component-btn-link{
        position: relative;
        background-image: linear-gradient(45deg, #000 50%, transparent 50%);
        background-position: 100%;
        background-size: 400%;
        -webkit-transition: all 0.6s cubic-bezier(.09,.17,.28,.62);
        transition: all 0.6s cubic-bezier(.09,.17,.28,.62);
    }
    .component-btn-link.act_effect{
        background-position: 0;
        //background-color: #000000;
    }
    .component-btn-link.act_effect{
        color: #ffffff !important;
        position: relative;
        z-index: 1;
    }

    .aluminum-component{
        .component-btn-link{
            position: relative;
            background-image: linear-gradient(45deg, #fff 50%, transparent 50%);
            background-position: 100%;
            background-size: 400%;
            -webkit-transition: all 0.6s cubic-bezier(.09,.17,.28,.62);
            transition: all 0.6s cubic-bezier(.09,.17,.28,.62);
        }
        .component-btn-link.act_effect{
            background-position: 0;
            //background-color: #000000;
        }
        .component-btn-link.act_effect{
            color: #000000 !important;
            position: relative;
            z-index: 1;
        }

    }

    .products-inner-itembox{
        .component-btn-link{
            position: relative;
            background-image: linear-gradient(45deg, #fff 50%, transparent 50%);
            background-position: 100%;
            background-size: 400%;
            -webkit-transition: all 0.6s cubic-bezier(.09,.17,.28,.62);
            transition: all 0.6s cubic-bezier(.09,.17,.28,.62);
        }
        .component-btn-link.act_effect{
            background-position: 0;
            //background-color: #000000;
        }
        .component-btn-link.act_effect{
            color: #000000 !important;
            position: relative;
            z-index: 1;
        }

    }

    @-webkit-keyframes iconanime {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
    }
    @keyframes iconanime {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
    }

    footer{
        .footer-contact{
            &-right{
                .component-btn-link.act_effect{
                    .icon-mail:before {
                        -webkit-animation: iconanime 1.5s cubic-bezier(.1,.135,.15,.86);
                        animation: iconanime 1.5s cubic-bezier(.1,.135,.15,.86);

                        background: url("../img/common/icon-mail_on.png") no-repeat;
                    }
                    .icon-catalog:before {
                        -webkit-animation: iconanime 1.5s cubic-bezier(.1,.135,.15,.86);
                        animation: iconanime 1.5s cubic-bezier(.1,.135,.15,.86);

                        background: url("../img/common/icon-catalog_on.png") no-repeat;
                    }
                }
            }
        }
            .component-btn-link{
                position: relative;
                background-image: linear-gradient(45deg, #fff 50%, transparent 50%);
                background-position: 100%;
                background-size: 400%;
                -webkit-transition: all 0.6s cubic-bezier(.09,.17,.28,.62);
                transition: all 0.6s cubic-bezier(.09,.17,.28,.62);
            }
            .component-btn-link.act_effect{
                background-position: 0;
                //background-color: #000000;
            }
            .component-btn-link.act_effect{
                color: #000000 !important;
                position: relative;
                z-index: 1;
            }

    }

    .ancer-component{
        .component-btn-link{
            position: relative;
            background-image: linear-gradient(45deg, #fff 50%, transparent 50%);
            background-position: 100%;
            background-size: 400%;
            -webkit-transition: all 0.6s cubic-bezier(.09,.17,.28,.62);
            transition: all 0.6s cubic-bezier(.09,.17,.28,.62);
        }
        .component-btn-link.act_effect{
            background-position: 0;
            //background-color: #000000;
        }
        .component-btn-link.act_effect{
            color: #000000 !important;
            position: relative;
            z-index: 1;
        }

    }