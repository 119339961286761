
.faq-main-title{
    @include w_sp{
        @include VW(width, 180);
    }
}

.pages-faq{
    .pages-keyvisual-title{
        transform: translate(-50%, 0);
    }
}
.faq-pages{
    .ancer-component-inner{
        @media only screen and (max-width : 1060px) {
            display: table;
            font-size: 0;
        }
    }
    .ancer-component-top{
        background: url("../img/ancer/ancer-products-items01.png") no-repeat center center;
        background-size: cover;
            @media only screen and (max-width : 1060px){
                display: block;
                width: 100%;
                background: url("../img/ancer/ancer-products-items01_sp.png") no-repeat center center;
                background-size: cover;
                @include VW(height, 180,$tb3);
            }
            @include w_sp{
                height: 100%;
            }
        .ancer-title{
            @include w_sp{
                img{
                    @include VW(width, 290);
                }
            }

        }
    }
    .ancer-component-left{
        background: url("../img/ancer/ancer-aluminum-items01.png") no-repeat center center;
        background-size: cover;
            @media only screen and (max-width : 1060px) {
                display: inline-block;
                vertical-align: top;
                background: url("../img/ancer/ancer-aluminum-items01_sp.png") no-repeat center center;
                background-size: cover;
                @include VW(height, 180,$tb3);
            }
            @include w_sp{
                height: 100%;
            }
        .ancer-title{
            @include w_sp{
                img{
                    @include VW(width, 290);
                }
            }

        }
    }

    .ancer-component-right{
        background: url("../img/ancer/ancer-story-items02.png") no-repeat center center;
        background-size: cover;
            @media only screen and (max-width : 1060px){
                display: inline-block;
                vertical-align: top;
                background: url("../img/ancer/ancer-story-items02_sp.png") no-repeat center center;
                background-size: cover;
                @include VW(height, 180,$tb3);
            }
            @include w_sp{
                height: 100%;
            }

        .ancer-title{
            @include w_sp{
                img{
                    @include VW(width, 200);
                }
            }

        }

    }
}





.faq-accodion{
    &-component{
        background-color: #ffffff;
        padding-bottom: 180px;
        @media only screen and (max-width : 925px) {
            @include VW(padding-bottom, 180);
        }
    }
    &-box{
        padding: 0;
        width: 900px;
        margin: auto;
        @media only screen and (max-width : 925px) {
            width: 85%;
        }

    }
    &-label {
        display: block;
        padding : 30px 10px;
        cursor :pointer;
        transition: all 0.5s;
        position: relative;
        top: 0;left: 0;
        font-size: 18px;
        border-top: 1px solid #cacaca;

        @include w_sp{
            font-size: 1rem;
            @include VW(padding-top, 50);
            @include VW(padding-bottom, 50);
            @include VW(padding-right, 37);
            @include VW(padding-left, 37);
        }

    }
    &-label:first-of-type{
        border-top: none;
    }
    &-label:before{
        content: "";
        width: 8px;
        height: 8px;
        border: 0px;
        border-top: solid 1px #605d5d;
        border-right: solid 1px #605d5d;
        -ms-transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);

        transition: all 0.3 ease-out;
        position: absolute;
        top: 50%;right: 10px;
    }
    &-label.toggle:before{
        content: "";
        -ms-transform: rotate(-46deg);
        -webkit-transform: rotate(-46deg);
        transform: rotate(-46deg);
    }
    &-input {
        display: none;
    }

    &-text{
        padding-left: 10px;
        overflow: hidden;
        transition: height 0.3s ease-out;
        line-height: 1.9;
        font-size: 14px;
        height: 0;
        @include w_sp{
            font-size: 0.8rem;
            @include VW(padding-left, 37);
        }
    }

}

.faq-accodion-label.toggle + .faq-accodion-text {
    height: auto;
    box-sizing: border-box;
    margin-bottom: 20px;
}