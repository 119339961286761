.aluminum-component{
    position: relative;
    top: 0;left: 0;
    padding-top: 125px;
    padding-bottom: 125px;
    @include BP_tbsp{
        @include VW(padding-top,125,$tb);
        @include VW(padding-bottom,125,$tb);

    }

    @include w_sp{
        @include VW(padding-top,125);
        @include VW(padding-bottom,100);
    }

    background-size: cover;

        &-layout{
            .component{
                &-layout{
                    width: 1200px;
                    align-items: baseline;
                    @include BP_tbsp{
                        width: 100%;
                    }

                    @include w_sp{
                        width: 85.746vw;
                        flex-wrap: wrap;
                    }
                        li{
                            @include w_sp{
                                width: 42.721vw;
                            }
                            img{
                                @include w_sp{
                                    @include VW(width,280);
                                }
                            }
                        }
                        li:nth-child(1){
                            img{
                                @include BP_tbsp{
                                    @include VW(width,117);
                                }

                                @include w_sp{
                                    @include VW(width,166);
                                }
                            }

                        }
                        li:nth-child(2){
                            img{
                                @include BP_tbsp{
                                    @include VW(width,111);
                                }

                                @include w_sp{
                                    @include VW(width,155);
                                }
                            }

                        }
                        li:nth-child(3){

                            img{
                                @include BP_tbsp{
                                    @include VW(width,75);
                                }

                                @include w_sp{
                                    @include VW(width,113);
                                }
                            }

                        }
                        li:nth-child(4){
                            img{
                                @include BP_tbsp{
                                    @include VW(width,83);
                                }

                                @include w_sp{
                                    @include VW(width,130);
                                }
                            }

                        }
                        li:nth-child(1n + 3){
                            @include w_sp{
                                @include VW(margin-top,70);
                            }

                        }

                }
            }
        }

        .page-aluminum,
        .page-top{
            li:first-child figure,
            li:nth-child(2) figure,
            li:nth-child(3) figure,
            li:nth-child(4) figure{
                width: 129px;
                margin: auto;
            }
            li:first-child figure{
                height: 110px;
               background: url("../img/common/aluminum/pict01.png") no-repeat bottom center;
                @include w_sp{
                   @include VW(width,160);
                   height: 19vw;
                   background: url("../img/common/aluminum/pict01_sp.png") no-repeat bottom center;
                   background-size: cover;
                }

            }
            li:nth-child(2) figure{
                height: 79px;
               background: url("../img/common/aluminum/pict02.png") no-repeat bottom center;
                @include w_sp{
                   @include VW(width,158);
                   @include VW(height,110);
                   background: url("../img/common/aluminum/pict02_sp.png") no-repeat bottom center;
                   background-size: cover;
                }

            }
            li:nth-child(3) figure{
                height: 90px;
               background: url("../img/common/aluminum/pict03.png") no-repeat bottom center;
                @include w_sp{
                   @include VW(width,100);
                   @include VW(height,110);
                   background: url("../img/common/aluminum/pict03_sp.png") no-repeat bottom center;
                   background-size: cover;
                }

            }
            li:nth-child(4) figure{
                height: 75px;
               background: url("../img/common/aluminum/pict04.png") no-repeat bottom center;
                @include w_sp{
                   @include VW(width,130);
                   @include VW(height,110);
                   background: url("../img/common/aluminum/pict04_sp.png") no-repeat bottom center;
                   background-size: cover;
                }

            }
        }
        .page-aluminum{
            li:first-child figure{
                height: 110px;
               background: url("../img/aluminum/pict01.png") no-repeat bottom center;
                @include w_sp{
                   @include VW(width,160);
                   height: 19vw;
                   background: url("../img/aluminum/pict01_sp.png") no-repeat bottom center;
                   background-size: cover;
                }

            }
            li:nth-child(2) figure{
                height: 79px;
               background: url("../img/aluminum/pict02.png") no-repeat bottom center;
                @include w_sp{
                   @include VW(width,158);
                   @include VW(height,110);
                   background: url("../img/aluminum/pict02_sp.png") no-repeat bottom center;
                   background-size: cover;
                }

            }
            li:nth-child(3) figure{
                height: 90px;
               background: url("../img/aluminum/pict03.png") no-repeat bottom center;
                @include w_sp{
                   @include VW(width,100);
                   @include VW(height,110);
                   background: url("../img/aluminum/pict03_sp.png") no-repeat bottom center;
                   background-size: cover;
                }

            }
            li:nth-child(4) figure{
                height: 87px;
               background: url("../img/aluminum/pict04.png") no-repeat bottom center;
                @include w_sp{
                   @include VW(width,115);
                   @include VW(height,100);
                   background: url("../img/aluminum/pict04_sp.png") no-repeat bottom center;
                   background-size: cover;
                }

            }

        }
    .component{
        &-title{
            text-align: center;
            @include w_sp{
                margin-bottom: 8vw;
            }
            img{
                @include BP_tbsp{
                    @include VW(width,284,$tb);
                }
                @include w_sp{
                    @include VW(width,344);
                }

            }

        }
        &-btn{
            border: 1px solid #ffffff;
            width: 435px;
            @include w_sp{
                border: 1px solid #ffffff;
                @include VW(width,600);
                @include VW(margin-top,80);
            }

            a{
                width: 435px;
                color: #ffffff;
                @include w_sp{
                    @include VW(width,600);
                    font-size: 1rem;
                    line-height: 1.5;
                }

            }
        }
        &-mintitle,
        &-text{
            color: #ffffff;
        }
        &-mintitle{
            font-size: 26px;
            @include BP_tbsp{
                font-size: 1.5rem;
            }
            @include w_sp{
                font-size: 1.1rem;
            }

        }
        &-text{
            margin-top: 20px;
            font-size: 14px;
            line-height: 1.6;
            @include w_sp{
                font-size: 0.7rem;
                @include VW(margin-top,30);
                line-height: 1.6;
            }

        }


    }
    &-suntitle{
        text-align: center;
        font-size: 16px;
        color:#ffffff;
        margin-bottom: 40px;
        @include w_sp{
            font-size: 4vw;
            margin-bottom: 8vw;
        }

    }
}
.aluminum-component:before{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;left: 0;
    background: url("../img/common/aluminum/aluminum-bg_pc.jpg") no-repeat center center;
    @include w_sp{
        background: url("../img/common/aluminum/aluminum-bg_sp.jpg") no-repeat center center;
        background-size: cover;
    }

    opacity: 0;
    transform: scale(1.1);
    transition: all 0.7s cubic-bezier(0.39, 0.575, 0.565, 1);
    background-size: cover;
    z-index: -1;
}

.aluminum-main-title{
    @include w_sp{
        @include VW(width, 391);
    }
}
.aluminum-pages{
    &-component{
        background: inherit;
        background-color: #ffffff;
        padding-top: 107px;
        padding-bottom: 115px;
        @media only screen and (max-width : 1060px) {
            @include VW(padding-top, 107,$tb3);
            @include VW(padding-bottom, 107,$tb3);
        }
        @include w_sp{
            @include VW(padding-top, 80);
            @include VW(padding-bottom, 132);
        }

        .component{
            &-text,
            &-mintitle{
                color: #000000;
            }

        }
    .main-subtitle{
        text-align: center;
        margin-bottom: 40px;
        @media only screen and (max-width : 1060px) {
            @include VW(margin-bottom, 40,$tb3);
            img{
                width: 95%;
            }
        }
        @include w_sp{
            @include VW(margin-bottom, 60);
        }


    }

    }
}

.aluminum-item01-component{
        position: relative;
        top: 0;left: 0;
        padding: 205px 0;
        @media only screen and (max-width : 1060px) {
            @include VW(padding-top, 205,$tb3);
            @include VW(padding-bottom, 205,$tb3);
        }
        @include w_sp{
            @include VW(padding-top, 120);
            @include VW(padding-bottom, 460);
        }
}
.aluminum-item01-component:before{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;left: 0;
    background: url("../img/aluminum/aluminum-item01_bg.jpg") no-repeat center center;
    opacity: 0;
    transform: scale(1.1);
    transition: all 0.7s cubic-bezier(0.39, 0.575, 0.565, 1);
    background-size: cover;
    z-index: -1;
        @include w_sp{
            background: url("../img/aluminum/aluminum-item01_bg_sp.jpg") no-repeat center center;
            transform: scale(1.1);
            transition: all 0.7s cubic-bezier(0.39, 0.575, 0.565, 1);
            background-size: cover;
        }
}
.aluminum-item02-component{
        position: relative;
        top: 0;left: 0;
        padding-top: 188px;
        padding-bottom: 150px;
        @media only screen and (max-width : 1060px) {
            @include VW(padding-top, 188,$tb3);
            @include VW(padding-bottom, 150,$tb3);
        }
        @include w_sp{
            @include VW(padding-top, 120);
            @include VW(padding-bottom, 460);
        }


}
.aluminum-item02-component:before{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;left: 0;
    background: url("../img/aluminum/aluminum-item02_bg.jpg") no-repeat center center;
    opacity: 0;
    transform: scale(1.1);
    transition: all 0.7s cubic-bezier(0.39, 0.575, 0.565, 1);
    background-size: cover;
    z-index: -1;
        @include w_sp{
            background: url("../img/aluminum/aluminum-item02_bg_sp.jpg") no-repeat center center;
            transform: scale(1.1);
            transition: all 0.7s cubic-bezier(0.39, 0.575, 0.565, 1);
            background-size: cover;
        }
}

.aluminum-item03-component{
        position: relative;
        top: 0;left: 0;

        padding-top: 115px;
        padding-bottom: 150px;
        @media only screen and (max-width : 1060px) {
            @include VW(padding-top, 119,$tb3);
            @include VW(padding-bottom, 150,$tb3);
        }
        @include w_sp{
            @include VW(padding-top, 120);
            @include VW(padding-bottom, 460);

        }


}
.aluminum-item03-component:before{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;left: 0;
    background: url("../img/aluminum/aluminum-item03_bg.jpg") no-repeat center center;
    opacity: 0;
    transform: scale(1.1);
    transition: all 0.7s cubic-bezier(0.39, 0.575, 0.565, 1);
    background-size: cover;
    z-index: -1;
        @include w_sp{
            background: url("../img/aluminum/aluminum-item03_bg_sp.jpg") no-repeat center center;
            transform: scale(1.1);
            transition: all 0.7s cubic-bezier(0.39, 0.575, 0.565, 1);
            background-size: cover;
        }
}

.aluminum-item-component-inner{
    width: 1060px;
    margin: auto;
    @media only screen and (max-width : 1065px) {
        width: 95%;
    }

}
.aluminum-item{
    &-textbox{
        width: 505px;
        color: #ffffff;
        font-size: 14px;
        line-height: 2;
        @media only screen and (max-width : 1065px) {
            @include VW(width, 505,$tb3);
        }
        @include w_sp{
            width: 100%;
        }

        p{
            margin-top: 30px;
            @media only screen and (max-width : 1065px) {
               @include VW(margin-top, 30,$tb3);
            }

        }
    }
}

.aluminum-item01-component,
.aluminum-item03-component{
    .aluminum-item{
        &-textbox{
            margin-right: auto;
            @include w_sp{
                 margin-left: auto;
            }

        }
    }
}
.aluminum-item02-component{
    .aluminum-item{
        &-textbox{
            margin-left: auto;
        }
    }
}
.aluminum-item01-component,
.aluminum-item02-component,
.aluminum-item03-component{
    .aluminum-item{
         &-textbox{
            h3{
                @include w_sp{
                    text-align: center;
                }
            }
            p{
                width: 90%;
                margin-right: auto;
                font-size: 14px;
                @include w_sp{
                    font-size: 0.9rem;
                    margin-left: auto;
                }

            }
         }
    }
}

.aluminum-item01-component{
    img{
        @include w_sp{
            @include VW(width, 532);
        }

    }
}
.aluminum-item02-component{
    img{
        @include w_sp{
            @include VW(width, 480);
        }

    }
}
.aluminum-item03-component{
    img{
        @include w_sp{
            @include VW(width, 514);
        }

    }
}
.aluminum-expert{
    &-component{
        padding-top: 107px;
        padding-bottom: 115px;
        @media only screen and (max-width : 1065px) {
            @include VW(padding-top, 107,$tb3);
            @include VW(padding-bottom, 115,$tb3);
        }
        .main-subtitle{
            img{
                @media only screen and (max-width : 1065px) {
                    //@include VW(width, 1059,$tb3);
                    width: 100%;
                }

            }
        }
    }
    &-mintitle{
        text-align: center;
        margin-top: 40px;
        font-size: 16px;
        @media only screen and (max-width : 1065px) {
            @include VW(margin-top, 40,$tb3);
        }

        @include w_sp{
             @include VW(margin-top, 50);
            font-size: 1rem;
        }

        span:first-child{
            padding-left: 16px;
            @media only screen and (max-width : 1065px) {
                @include VW(padding-left, 16,$tb3);
            }

        }
        span:last-child{
            padding-left: 16px;
            @media only screen and (max-width : 1065px) {
                @include VW(padding-left, 16,$tb3);
            }

        }
    }
    &-area{
        p{
            color: #000000;
            @include w_sp{
                font-size: 0.8rem;
            }

        }
    }
    &-area01,
    &-area02{
        display: flex;
        justify-content: space-between;
        @include w_sp{
           display: block;
           width: 90%;
            margin-left: auto;
            margin-right: auto;
        }

    }
    &-area01{
        margin-top: 95px;
        @include w_sp{
           @include VW(margin-top, 90);
        }

        .aluminum-item-textbox{
            padding-top: 23px;
            padding-bottom: 50px;
            line-height: 2.2;
            width: 530px;
            @media only screen and (max-width : 1060px) {
                @include VW(padding-top, 23,$tb3);
                @include VW(padding-bottom, 50,$tb3);
                @include VW(width, 530,$tb3);
            }
            @include w_sp{
                @include VW(padding-top, 0);
                @include VW(padding-bottom, 75);
                width: 100%;
            }

            img{
                 @media only screen and (max-width : 1060px) {
                        @include VW(width, 427,$tb3);
                 }
                @include w_sp{
                    @include VW(width, 568);
                }

            }
        }
        figure{
            width: 440px;
            height: 580px;

            background: url("../img/aluminum/aluminum-expert01-pict.png") no-repeat center center;
            @media only screen and (max-width : 1060px) {
                @include VW(width, 440,$tb3);
                @include VW(height, 580,$tb3);
                background-size: cover;
            }
            @include w_sp{
                @include VW(width, 440);
                @include VW(height, 580);
                background: url("../img/aluminum/aluminum-expert01-pict_sp.png") no-repeat center center;
                background-size: cover;
                margin: auto;
            }


        }
    }
    &-area02{
        margin-top: 110px;
        flex-flow: row-reverse;

        @media only screen and (max-width : 1060px) {
            @include VW(margin-top, 110,$tb3);
        }
        .aluminum-item-textbox{
            padding-top: 60px;
            position: relative;
            right: 40px;
            line-height: 2.2;
            width: 555px;

            @media only screen and (max-width : 1060px) {
                @include VW(padding-top, 60,$tb3);
                @include VW(width, 555,$tb3);
                @include VW(right, 40,$tb3);
            }
            @include w_sp{
                @include VW(right, 0);
                @include VW(padding-bottom, 75);
                width: 100%;
            }

            img{
                 @media only screen and (max-width : 1060px) {
                        @include VW(width, 560,$tb3);
                 }
                @include w_sp{
                    @include VW(width, 499);
                }

            }

        }

        figure{
            width: 560px;
            height: 415px;
            background: url("../img/aluminum/aluminum-expert02-pict.png") no-repeat center center;
            @media only screen and (max-width : 1060px) {
                @include VW(width, 560,$tb3);
                @include VW(height, 415,$tb3);
                background-size: cover;
            }
            @include w_sp{
                @include VW(width, 558);
                @include VW(height, 417);
                background: url("../img/aluminum/aluminum-expert02-pict_sp.png") no-repeat center center;
                background-size: cover;
                margin: auto;
            }



        }

    }
    &-area03{
        margin-top: 110px;
        @media only screen and (max-width : 1060px) {
            @include VW(margin-top, 110,$tb3);
        }
        @include w_sp{
           width: 90%;
            margin-left: auto;
            margin-right: auto;
        }

        .aluminum-item-textbox{
            width: 100%;
            text-align: center;
            @include w_sp{
                text-align: left;
            }

            img{
                @include w_sp{
                    @include VW(width, 397);
                }

            }
        }
    }
}

.aluminum-pages{
        .ancer-component-left{
        background: url("../img/ancer/ancer-products-items01.png") no-repeat center center;
        background-size: cover;
            @include w_sp{
                background: url("../img/ancer/ancer-products-items01_sp.png") no-repeat center center;
                background-size: cover;
            }

        .ancer-title{
            @include w_sp{
                img{
                    @include VW(width, 290);
                }
            }

        }

    }
    .ancer-component-right{
        background: url("../img/ancer/ancer-story-items02.png") no-repeat center center;
        background-size: cover;
            @include w_sp{
                background: url("../img/ancer/ancer-story-items02_sp.png") no-repeat center center;
                background-size: cover;
            }

        .ancer-title{
            @include w_sp{
                img{
                    @include VW(width, 200);
                }
            }

        }

    }

}
