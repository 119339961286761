.products-component{
    background-color: #fff;
    padding-top: 125px;
    padding-bottom: 125px;
    @include BP_tbsp{
        @include VW(padding-top,125,$tb);
        @include VW(padding-bottom,125,$tb);
    }

    @include w_sp{
        @include VW(padding-top,125);
        @include VW(padding-bottom,125);
    }
        &-layout{
            .component{
                &-layout{
                    width: 1200px;
                        @include BP_tbsp{
                           width: 100%;
                            img{
                                width: 100%;
                            }
                        }
                        @include w_sp{
                            @include VW(width,600);
                            flex-wrap: wrap;
                        }
                        li{
                            @include w_sp{
                                @include VW(width,280);
                            }
                            img{
                                @include w_sp{
                                    @include VW(width,280);
                                }
                            }
                        }
                        li:nth-child(1n + 2){
                            opacity: 0.5;
                        }

                        li:nth-child(1n + 3){
                            @include w_sp{
                                @include VW(margin-top,70);
                            }

                        }
                }
            }
        }
    .component{
        &-title{
            text-align: center;
            margin-bottom: 74px;
            @include BP_tbsp{
                @include VW(margin-bottom,74,$tb);
            }

            @include w_sp{
                  @include VW(margin-bottom,100);
            }
            img{
                @include BP_tbsp{
                    @include VW(width,281,$tb);
                }
                @include w_sp{
                    @include VW(width,344);
                }

            }
        }
        &-btn{
            border: 1px solid #000000;
            width: 250px;

            @include w_sp{
                border: 1px solid #000000;
                @include VW(width,600);
                @include VW(margin-top,80);
            }

            a{
                width: 250px;
                color: #000000;

                @include w_sp{
                    @include VW(width,600);
                    font-size: 1rem;
                }

            }

        }
    }
}
.products-main-title{
    @include w_sp{
        @include VW(width, 386);
    }
}
.pages-keyvisual-content--products-inner{
    .products-main-title{
        @include w_sp{
            @include VW(width, 294);
        }
    }
}


.products-pages{
    .ancer-component-left{
        background: url("../img/ancer/ancer-aluminum-items01.png") no-repeat center center;
        background-size: cover;
            @include w_sp{
                background: url("../img/ancer/ancer-aluminum-items01_sp.png") no-repeat center center;
                background-size: cover;
            }

        .ancer-title{
            @include w_sp{
                img{
                    @include VW(width, 290);
                }
            }

        }
    }

    .ancer-component-right{
        background: url("../img/ancer/ancer-story-items02.png") no-repeat center center;
        background-size: cover;
            @include w_sp{
                background: url("../img/ancer/ancer-story-items02_sp.png") no-repeat center center;
                background-size: cover;
            }

        .ancer-title{
            @include w_sp{
                img{
                    @include VW(width, 200);
                }
            }

        }

    }
}
.products-list{
    &-component{
        width: 100%;
        background-color: #ffffff;
    }
    &-items-inner{
        width: 900px;
        @media only screen and (max-width : 900px) {
            width: 95%;
        }

        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include w_sp{
            display: block;
            width: 85%;
        }

    }


    &-items{
        padding: 90px 0;
        @media only screen and (max-width : 900px) {
            @include VW(padding-top, 90,$tb2);
            @include VW(padding-bottom, 90,$tb2);
        }
        @include w_sp{
            @include VW(padding-top, 90);
            @include VW(padding-bottom, 90);

        }

    }

    &-items:nth-child(2n){
        background-color: #f7f7f7;
    }
    &-items-pict01{
        background: url("../img/products/products-items01.png") no-repeat center center;
    }
    &-items-pict02{
        background: url("../img/products/products-items02.png") no-repeat center center;
    }
    &-items-pict03{
        background: url("../img/products/products-items03.png") no-repeat center center;
    }
    &-items-pict04{
        background: url("../img/products/products-items04.png") no-repeat center center;
    }


    &-items-pict{
        width: 340px;
        height: 412px;
        @media only screen and (max-width : 900px) {
            @include VW(width, 340,$tb2);
            @include VW(height, 412,$tb2);
            background-size: cover;
        }
        @include w_sp{
            margin: auto;
        }
    }
    &-items-textbox{
        width: 450px;
        @media only screen and (max-width : 900px) {
            @include VW(width, 450,$tb2);
        }
        @include w_sp{
           width: 100%;
        }
    }

    &-items-mintitle{
        font-size: 18px;
        @include w_sp{
            @include VW(margin-top, 30);
            text-align: center;
             font-size: 1.1rem;
        }
    }


    &-items-title{
        margin-top: 20px;
        font-size: 36px;
        font-weight: normal;
        @include w_sp{
            @include VW(margin-top, 20);
            text-align: center;
            font-size: 1.6rem;
        }

    }

    &-items-text{
        margin-top: 30px;
        font-size: 14px;
        line-height: 2;
        @include w_sp{
            @include VW(margin-top, 30);
            font-size: 0.9rem;
        }


    }

    &-btn{
        width: 260px;
        border: 1px solid #000000;
        margin-top: 50px;
        @include w_sp{
            @include VW(width, 590);
            @include VW(margin-top, 60);
            margin-left: auto;
            margin-right: auto;
        }
        a{
            color: #000000;
            font-size: 16px;
            text-align: center;
            width: 260px;
            height: 75px;
            display: table-cell;
            vertical-align: middle;
        @include w_sp{
            @include VW(width, 590);
            @include VW(height, 100);
             font-size: 1rem;
        }

        }
    }
}

    .products-items02 .products-list-btn,
    .products-items03 .products-list-btn,
    .products-items04 .products-list-btn{
        visibility: hidden;
    }
    .products-items02 .products-list-items-textbox,
    .products-items03 .products-list-items-textbox,
    .products-items04 .products-list-items-textbox{
        opacity: 0.2;
    }
    .products-items02 .products-list-btn .component-btn-link,
    .products-items03 .products-list-btn .component-btn-link,
    .products-items04 .products-list-btn .component-btn-link{
        visibility: hidden;
    }


.products-inner{
    &-mainbox{
        visibility: visible;
        opacity: 1;
        overflow: inherit;
        .products-inner-item{
            opacity: 1;
            visibility: visible;
        }
    }
    &-itembox{
        background-color: #000000;
        @include w_sp{
           margin-top: -40vw;
           .products-inner-item{
              overflow: hidden;
           }
           .products-inner-item-text {
                margin-top: 53.774vw;
            }
            .products-inner-item-title{
                position: relative;
                left: 25vw;
                top: 25vw;
            }
            .products-inner-item-description-main{
                width: 95%;
                margin-right: auto;
            }
        }

    }
    &-item{
        display: flex;
        width: 1060px;
        margin: auto;
        @media only screen and (max-width : 1060px){
            width: 95%;
        }
        @include w_sp{
            width: 100%;
            display: block;
        }
    }
    &-item01,
    &-item03{
        .products-inner-item-description{
            width: 470px;
            margin-right: auto;
            @media only screen and (max-width : 1060px){
                @include VW(width, 470, $tb3);
            }
             @include w_sp{
                 width:95%;
                 margin-left: auto;
             }

        }
    }
    &-item01{
        .products-inner-item-description{
            margin-top: 200px;
             @media only screen and (max-width : 1060px){
                 @include VW(margin-top, 200, $tb3);
                 img{
                     @include VW(width, 381, $tb3);
                 }
             }
             @include w_sp{
                 @include VW(margin-top, 80);
                img{
                     @include VW(width, 511);
                 }
             }
        }
    }
    &-item03{
        .products-inner-item-description{
            margin-top: 120px;
            margin-bottom: 140px;
            width: 500px;
            @media only screen and (max-width : 1060px){
                 @include VW(margin-top, 120, $tb3);
                 @include VW(margin-bottom, 140, $tb3);
                 @include VW(width,500, $tb3);
                 img{
                     @include VW(width,498, $tb3);
                 }
             }
            @include w_sp{
                width: 95%;
                @include VW(margin-bottom, 60);
            }

        }
        .products-inner-item-comparisonbox{
            margin-top: 50px;
            @media only screen and (max-width : 1060px){
                @include VW(margin-top, 50, $tb3);
            }
        }
        .products-inner-item-comparison{
            display: flex;
            align-items: center;
            border-top: 1px solid #ffffff;
            border-bottom: 1px solid #ffffff;
            padding-top: 20px;
            padding-bottom: 20px;
            @media only screen and (max-width : 1060px){
                @include VW(padding-top, 20, $tb3);
                @include VW(padding-bottom, 20, $tb3);
            }
            @include w_sp{
                display: block;
                width: 95%;
                margin: auto;
                padding-top: 0;
                padding-bottom: 0;
            }

                 &-text{
                     color: #ffffff;
                     font-size: 16px;
                     text-align: center;
                     line-height: 1.6;
                     padding-left: 20px;
                     padding-right: 15px;
                     padding-top: 30px;
                    padding-bottom: 30px;
                     border-right: 1px solid #ffffff;
                    @media only screen and (max-width : 1060px){
                        @include VW(padding-top, 30, $tb3);
                        @include VW(padding-bottom, 30, $tb3);
                        @include VW(padding-left, 20, $tb3);
                        @include VW(padding-right, 15, $tb3);
                    }
                    @include w_sp{
                        border-bottom: 1px solid #ffffff;
                        border-right: none;
                        font-size: 4vw;
                        @include VW(padding-top, 40);
                        @include VW(padding-bottom, 40);
                        @include VW(padding-left, 30);
                        @include VW(padding-right, 30);
                        width: 80%;
                        margin: auto;
                    }

                 }
                 &-definition{
                     color: #ffffff;
                     font-size: 15px;
                     width: 256px;
                     padding-left: 30px;
                     padding-right: 30px;
                     overflow: hidden;
                      @media only screen and (max-width : 1060px){
                          @include VW(width, 256, $tb3);
                          @include VW(padding-left, 30, $tb3);
                          @include VW(padding-right, 30, $tb3);
                      }
                        @include w_sp{
                            font-size: 3.7vw;
                            width: 70%;
                            margin: auto;
                        @include VW(padding-top, 40);
                        @include VW(padding-bottom, 40);

                        }

                     dt{
                         float: left;
                         clear: left;
                         margin-top: 20px;
                         @media only screen and (max-width : 1060px){
                             @include VW(margin-top, 20, $tb3);
                         }
                         @include w_sp{
                            @include VW(margin-top, 24);
                         }
                     }
                     dd{
                         float: right;
                         margin-top: 20px;
                        @media only screen and (max-width : 1060px){
                             @include VW(margin-top, 20, $tb3);
                         }
                        @include w_sp{
                            @include VW(margin-top, 24);
                         }
                        small{
                            font-size: 10px;
                            position: relative;
                            top: -4px;
                            @include w_sp{
                                font-size: 2vw;
                            }

                        }

                     }
                     dt:first-child,
                     dd:nth-child(2){
                        margin-top: 0;
                     }
                     .plus{
                         color: #d1d032;
                     }
                 }
            &-thermography{
                display: flex;
                justify-content: center;
                margin-top: 40px;
                @media only screen and (max-width : 1060px){
                    @include VW(margin-top, 40, $tb3);
                }
                @include w_sp{
                    @include VW(margin-top, 70);
                }
                figure{
                    width: 190px;
                    @media only screen and (max-width : 1060px){
                        @include VW(width, 190, $tb3);
                        img{
                            @include VW(width, 190, $tb3);
                        }
                    }
                    @include w_sp{
                        @include VW(width, 248);
                        img{
                            @include VW(width, 248);
                        }

                    }
                }
                figure:first-child{
                    margin-right: 63px;
                    @media only screen and (max-width : 1060px){
                        @include VW(margin-right, 63, $tb3);
                    }
                    @include w_sp{
                        @include VW(margin-right, 80);
                    }

                }
                figcaption{
                    font-size: 16px;
                    color: #ffffff;
                    text-align: center;
                    margin-top: 20px;
                }
            }
        }
    }
    &-item02{
        flex-flow: row-reverse;
        .products-inner-item-description{
        width: 470px;
           margin-left: auto;
        }
    }
    &-item02{
        .products-inner-item-description{
            margin-top: 110px;
            @media only screen and (max-width : 1060px){
                @include VW(width,470, $tb3);
                 @include VW(margin-top, 110, $tb3);
                 img{
                     @include VW(width,232, $tb3);
                 }
             }
             @include w_sp{
                width: 95%;
                margin-right: auto;
                img{
                    @include VW(width,309);
                }

             }

        }

    }
    &-item-description-contents{
        height: 550px;
        background-color: #000000;
        background: url("../img/products/ar/products-items-bg01.png") no-repeat top center;
        background-size: cover;
        overflow: hidden;

        transition: all 0.4s ease-out;
        @media only screen and (max-width : 1060px){
            @include VW(height, 550, $tb3);
            background: url("../img/products/ar/products-items-bg01.png") no-repeat top center;
            background-size: cover;
        }
        @include w_sp{
            height: 100%;
            background: url("../img/products/ar/products-items-bg01_sp.png") no-repeat top center;
            background-size: cover;
        }
    }

    &-item-subarea{
        width: 100%;
        height: 578px;
        background-color: #000000;
        background: url("../img/products/ar/products-items-sub-bg.png") no-repeat bottom center;
        background-size: cover;
        overflow: hidden;
        padding-top: 76px;
        margin-top: 130px;

        opacity: 0 !important;
        visibility: hidden !important;
        transition: all 0.4s ease-out;

        .products-inner{
            width: 900px;
            margin: 0 auto;
            @media only screen and (max-width : 1060px){
                @include VW(width, 900, $tb3);
            }

            .main-subtitle{
                margin-top: 170px;
                @media only screen and (max-width : 1060px){
                    @include VW(margin-top, 170, $tb3);
                }
                img{
                    @media only screen and (max-width : 1060px){
                        @include VW(width, 450, $tb3);
                    }
                }
            }
        }
        @media only screen and (max-width : 1060px){
            @include VW(height, 578, $tb3);
            padding-top: 7.16981vw;
            @include VW(margin-top, 130, $tb3);

            background: url("../img/products/ar/products-items-sub-bg.png") no-repeat bottom center;
            background-size: cover;
        }
        @include w_sp{
            @include VW(height, 700);
            background: url("../img/products/ar/products-items-sub-bg_sp.png") no-repeat bottom center;
            background-size: cover;
            .products-inner{
                @include VW(width, 568);
                margin: 0 auto;
                .main-subtitle{
                    margin-top: 27vw;
                    img{
                        @include VW(width, 568);
                    }
                }
            }
        }
    }
    &-item-description-contents:nth-child(5){
        height: auto;
    }
    &-item-description-contents:nth-of-type(5n){
        height: 520px;
         @media only screen and (max-width : 1060px){
             @include VW(height, 520, $tb3);
         }
        @include w_sp{
             height: 100%;
         }

    }
    &-item-description-contents:nth-of-type(4n){
         @include w_sp{
             height: 100%;
         }
    }
    &-item-title{
        text-align: center;
         @media only screen and (max-width : 1060px){
             img{
                 @include VW(width, 190,$tb3);
             }
         }
         @include w_sp{
             text-align: center !important;
             img{
                 @include VW(width, 210);
             }
         }
    }
    &-item-text{
        color: #ffffff;
        text-align: center;
        font-size: 15px;
        line-height: 2;
        margin-top: 40px;
         @media only screen and (max-width : 1060px){
             @include VW(margin-top , 40, $tb3);
         }
         @include w_sp{
             width: 95%;

             text-align: left;
             font-size: 0.9rem;
             @include VW(margin-top , 40);
             margin-left: auto;
             margin-right: auto;
         }
    }
    &-item01,
    &-item02,
    &-item03{
        .products-inner-item-title,
        .products-inner-item-text{
            text-align: left;
        }
    }

    &-item-pict01{
        position: relative;
        top: 0;left: 0;
    }
    &-item-pict01:before{
        content: "";
        position: absolute;
        top: -250px;left: -34px;
        width: 574px;
        height: 873px;
        transform: translateY(10%);
        transition: all 0.5s ease-out;
        background: url("../img/products/ar/products-item-pict01.png") no-repeat;
        opacity: 0;
        z-index: 50;
        @media only screen and (max-width : 1060px){
            @include VW(top, -250, $tb3);
            @include VW(left, -34, $tb3);
            @include VW(width, 574, $tb3);
            @include VW(height, 874, $tb3);
            background: url("../img/products/ar/products-item-pict01.png") no-repeat;
            background-size: 100%;
        }
        @include w_sp{
            top: -2vw;
            left: 4vw;
            @include VW(width, 386);
            @include VW(height, 590);
            background: url("../img/products/ar/products-item-pict01.png") no-repeat;
            background-size: 100%;
        }
    }

    &-item-subarea.show,
    &-item-subarea.show *{
        opacity: 1 !important;
        visibility: visible !important;
    }
    &-item-subarea.show:before{
        opacity: 1;
        transform: translateY(0);
    }

    &-item-pict01.show{
        opacity: 1;
        visibility: visible;
    }
    &-item-pict01.show + .products-inner-item-description-main,
    &-item-pict01.show + .products-inner-item-description-main *{
        opacity: 1;
        visibility: visible;
    }
    &-item-pict01.show:before{
        opacity: 1;
        transform: translateY(0);
    }
    &-item-pict{
        position: relative;
        top: 0;left: 0;
    }
    &-item-pict02{
        span{
            @include w_sp{
               @include VW(height, 480);
               display: block;
            }
        }
    }
    &-item-pict03{
        span{
            @include w_sp{
               @include VW(height, 476);
               display: block;
            }
        }
    }
    &-item-pict04{
        span{
            @include w_sp{
                height: 49.18905vw;
               display: block;
            }
        }
    }
    &-item-pict02:before{
        content: "";
        position: absolute;
        top: 90px;
        right: -50px;
        width: 538px;
        height: 511px;
        background: url("../img/products/ar/products-item-pict02.png") no-repeat;
        opacity: 0;
        transform: translateY(15%);
        transition: all 0.7s ease-in-out;
        @media only screen and (max-width : 1060px){
            @include VW(top, 90, $tb3);
            @include VW(right, -50, $tb3);
            @include VW(width, 538, $tb3);
            @include VW(height, 511, $tb3);
            background: url("../img/products/ar/products-item-pict02.png") no-repeat;
            background-size: 100%;
        }
        @include w_sp{
            @include VW(top, 50);
            left:50%;
            //transform: translateX(-50%);
            @include VW(margin-left, -250);
            @include VW(width, 500);
            @include VW(height, 480);
            background: url("../img/products/ar/products-item-pict02.png") no-repeat;
            background-size: 100%;
        }

    }
    &-item-pict03:before{
        content: "";
        position: absolute;
        top: -280px;
        left: -150px;
        width: 740px;
        height: 879px;
        background: url("../img/products/ar/products-item-pict03.png") no-repeat;
        opacity: 0;
        transform: translateY(10%);
        transition: all 0.7s ease-in-out;
        @media only screen and (max-width : 1060px){
            @include VW(top, -280, $tb3);
            @include VW(left, -150, $tb3);
            @include VW(width, 740, $tb3);
            @include VW(height, 879, $tb3);
            background: url("../img/products/ar/products-item-pict03.png") no-repeat;
            background-size: 100%;
        }
        @include w_sp{
            @include VW(top, 95);
            @include VW(left, 0);
            width: 100%;
            @include VW(height, 476);
            background: url("../img/products/ar/products-item-pict03_sp.jpg") no-repeat;
            background-size: 100%;
        }

    }
    &-item-pict04:before{
        content: "";
        position: absolute;
        top: 120px;
        right: -420px;
        width: 877px;
        height: 654px;
        background: url("../img/products/ar/products-item-pict04.png") no-repeat;
        opacity: 0;
        transform: translateY(10%);
        transition: all 0.7s ease-in-out;
        @media only screen and (max-width : 1060px){
            @include VW(top, 120, $tb3);
            @include VW(right, -420, $tb3);
            @include VW(width, 877, $tb3);
            @include VW(height, 654, $tb3);
            background: url("../img/products/ar/products-item-pict04.png") no-repeat;
            background-size: 100%;
        }
        @include w_sp{
            @include VW(top, 0);
            left: auto;
            right: -20vw;
            @include VW(width, 660);
            @include VW(height, 500);
            background: url("../img/products/ar/products-item-pict04.png") no-repeat;
            background-size: 100%;
        }

    }

    &-item-pict01.act{
        width: 610px;
    }
}
.products-inner-item-description{
    &-main{
        width: 512px;
        margin-left: auto;
        position: relative;
        z-index: 20;
        top: -50px;
        @media only screen and (max-width : 1060px){
            @include VW(top, -50, $tb3);
            @include VW(width, 462, $tb3);
        }
        .component-btn{
            width: 320px;
            margin-top: 40px;
            @media only screen and (max-width : 1060px){
                 @include VW(width, 320, $tb3);
                 @include VW(margin-top, 40, $tb3);
            }
            @include w_sp{
                @include VW(width, 590);
                @include VW(margin-top, 70);
            }

        }
    }
    .component-btn{
        background-color: #118c4b;
        a{
            color: #ffffff;
        }

    }

}

.products-component-active-link{
    color: #000000;
}